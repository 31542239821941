import React from 'react';
import UpdateDeck from '../components/admin/udpates/UpdateDeck';

const AdminPage = () => {

   

    return (
        <div>
        <br/><br/><br/><br/><br/>
        Admin Page Test<br/>
        Logged In<br/>
        <UpdateDeck/>
        </div>
    )
}



export default AdminPage;