import React            from 'react';
import { NavLink }      from 'react-router-dom';
import * as routes      from '../../constants/routes';

import Hamburger        from './hamburger';

import './nav.css';

const NavMenu = ({user, clickSignOut, clearReading, openSignIn}) => {
    const toggleNavMenu = () => {
        const menu = document.getElementById('nav-menu');
        menu.classList.toggle('view-menu');
        menu.classList.toggle('hide-menu');
        document.getElementById('hamburger-btn').classList.toggle('hamburger-close');
    };
    
    return (<>
        <Hamburger hamburgerFunction={toggleNavMenu}/>
        <div id="nav-menu" className="hide-menu">
            <div>
                {/* clearReading(); = Must ensure layout is cleared when page changes.  App.js */}
                <NavLink exact to={routes.ROOT} onClick={() => {toggleNavMenu(); clearReading();}}>HOME</NavLink>
                <NavLink to={routes.READ} onClick={() => {toggleNavMenu(); clearReading();}}>READING</NavLink>
                <NavLink to={routes.LIBR} onClick={() => {toggleNavMenu(); clearReading();}}>LIBRARY</NavLink>

                {/* routs.ACCT = Must activate to upload new schema up to firebase */}
                {/* { user.uid && <NavLink to={routes.ACCT} onClick={() => {toggleNavMenu(); clearReading();}} >ACCOUNT</NavLink> } */}
                {/* { user.uid ? <button className="signout-" onClick={()=>{clickSignOut();toggleNavMenu();}}>Sign Out</button> : <button id="login-btn"  onClick={()=>{openSignIn();toggleNavMenu();}} >Login</button> } */}
            </div>
        </div>
    </>);
};

export default NavMenu;