import React                                        from 'react';
import styled                                       from 'styled-components';
import { Routes, Route, useLocation, NavLink }      from 'react-router-dom';
import * as routes                                  from './constants/routes';

import NavMenu                                      from './components/nav';
import Dropdown                                     from './components/DropDown';

const Header = ({ user, clearReading, clickSignOut, openSignIn, layout, layouts, selectLayout }) => {
    // Use page location to specify headery styles
    let location = useLocation().pathname.replace('/','');
    if (!location) {
        location = 'home';
    }

    return(
        <HeaderWrapper className={`${location}`}>
            <section className="section-1">
            <NavLink exact to={routes.ROOT} onClick={() => {clearReading();}}><img src="logo512.png" width="24px" height="24px" alt="temp logo"/><h3 className="Header-title">tarotfied</h3></NavLink>
            </section>

            <section className="section-2">
                <Routes>
                    <Route path={routes.READ} exact element={
                        layout.id
                        ? <h4>{layout.name}</h4>
                        : <Dropdown 
                            list={layouts} 
                            defaultText={"Choose a Spread"}
                            defaultValue={""}
                            selectedData={layout}
                            selectFunction={selectLayout} 
                            defaultOption={true}
                            />
                    } />
                </Routes>
            </section>

            <section className="section-3">
                <NavMenu 
                    user={user} 
                    clickSignOut={clickSignOut} 
                    openSignIn={openSignIn} 
                    clearReading={clearReading}
                    />
            </section>
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.header`
    height: 46px;
    padding: 6px 40px;
    border-bottom: 1px solid hsla(0, 0%, 83%, 0.4);
    overflow: hidden;

    background-color: #110C0E;
    color: var(--color-hex-lightgold);

    position: relative;
    display: grid;
    grid-template-areas: 'logo mid nav';
    grid-template-columns: 240px 1fr 240px;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  & > section {
    height: 100%;
    /* min-height: 46px; */
    display: flex;
    align-items: center;
    position: relative;
  }
  & > .section-1 {
    justify-content: flex-start;
    grid-area: logo;
  }
  & > .section-2 {
    justify-content: center;
    grid-area: mid;
  }
  & > .section-3 {
    justify-content: flex-end;
    grid-area: nav;
  }
  
  @media (max-width: 639px) {
    &.home,
    &.library {
      height: 32px;
      padding: 16px 16px 10px;
      grid-template-columns: 1fr 80px;
      grid-template-rows: auto;
      grid-template-areas: 'logo nav';
    }
    &.reading {
      height: 80px;
      padding: 16px 16px 10px;
      grid-template-columns: 1fr 80px;
      grid-template-rows: auto auto;
      grid-template-areas: 'logo nav' 'mid mid';
    }
    &.library > .section-2,
    &.home > .section-2 {
        display: none;
      }
  }



`;




export default Header;