import React, { useEffect, useState }   from 'react';

import styled from 'styled-components';

import Deck                                 from '../../components/deck';
import Layout                               from '../../components/layouts';
import {ReactComponent as ShuffleIcon}      from './icons/shuffle-icon.svg';
import {ReactComponent as OrientationIcon}  from './icons/orientation-icon.svg';
import {ReactComponent as RefreshIcon}      from './icons/restart-icon.svg';
// import { useNavigate }                  from 'react-router-dom';
//==> Back button to return user to previous page
// const navigate = useNavigate();
// const goBack = () => {
//     navigate(-1);
// 
//==> Insert 
// onClick={goBack}

const Reading = ({ deck, hand, layout, setHand, clearReading, toggleSetOrientation, viewCard }) => {

    //==> pass original deck and make new state of deal
    const [newDeck, setNewDeck] = useState([]);
    //==> signal difference in splay speed (shuffle vs reshuffle)
    const [reshuffle, setReshuffle] = useState(false);

    useEffect(() => {
        //==> spread out deck when page loads
        shuffleDeck();
        setTimeout(() => {
            const hideTopCard = document.getElementById('top-back-card');
            if(hideTopCard){ hideTopCard.style.display = 'none'; }
            
            displayDeck();

            setReshuffle(true);
        }, 500);    
    }, [deck]);

    const shuffleDeck = () => {
        //==> shuffle deck and update newDeck state
        let getDeck = [...deck];
        let shuffledDeck = [];
        while (getDeck.length > 0) {
            let index = Math.floor(Math.random() * getDeck.length);
            let card = getDeck[index];
            shuffledDeck.push(card);
            getDeck.splice(index, 1);
        };
        setNewDeck(shuffledDeck);
    };

    const shuffleBtn = () => {
        shuffleDeck();
        stackDeck();
        //==> shuffle options (shuffle vs reshuffle) for timing of display
        if (reshuffle === true){
            //=> reshuffle click, delay until stack is complete
            setTimeout(() => {
                displayDeck();
            }, 2800);
        } else {     
            //=> first shuffle click     
            setTimeout(() => {
                displayDeck();
                setReshuffle(true);
            }, 0);
        };
    };

    const displayDeck = () => {
        const getCard = document.getElementsByClassName('dealt-card');
        for (let i = 0; i < getCard.length; i++) {
            setTimeout(() => {
                setTimeout(() => {
                    if(getCard[i]){
                        getCard[i].classList.remove('stack-deck');
                        getCard[i].classList.add('splay-deck');
;                    }
                }, i*6);
            }, 0);
        };
    };
    const stackDeck = () => {
        const getCard = document.getElementsByClassName('dealt-card');
        for (let i = 0; i < getCard.length; i++) {
            setTimeout(() => {
                setTimeout(() => {
                    if(getCard[i]){
                        getCard[i].classList.add('stack-deck');
                        getCard[i].classList.remove('splay-deck');
                    }
                }, i*6);
            }, 0);
        };
    };

    //==> pick card from shuffled deck, add to hand.
    const selectCard = (e) => {
        const _id = e.currentTarget.id;
        //==> increase chances of card being upright
        const upDownChance = Math.floor(Math.random(1 - 0) * 3);
        if (hand.length < layout.cards) {
            document.getElementById(_id).style.display = "none";
            newDeck.filter(card => {
                if ( _id == card.id ) {
                    let drawnCard = card;
                    drawnCard.rotation = upDownChance;
                    drawnCard.orderNum = hand.length;
                    setHand([...hand, drawnCard]);
                };
            });
            newDeck.pop();

            if (layout.cards - 1 === hand.length){
                document.getElementById('shuffle-btn').setAttribute("disabled","disabled");
            };
        };
    };

    const refreshReading = () => {
        clearReading();
        setNewDeck(deck);
        setReshuffle(false);
        displayDeck();
        //==> Return drawn cards
        document.querySelectorAll('.dealt-card') .forEach((i) => {i.classList.add('splay-deck'); i.classList.remove('stack-deck'); i.style.display = 'block'})
        shuffleDeck();
    };

    const toggleOrientation = () => {
        document.getElementById('orientation-btn').classList.toggle('toggle-orientation-btn');
        if(document.querySelector('.spread-section')){
            document.querySelector('.spread-section').classList.toggle('toggle-orientation');
        }
        //==> pass to App parent
        toggleSetOrientation();
    };

    return (
        <ReadingWrapper className="draw-display">
            <ReadingMenu className="reading-menu">
                <section className="blank">

                </section>
                <section className="shuffle">
                    <button id="shuffle-btn" className="btn" title="shuffle deck" onClick={shuffleBtn}>Shuffle Deck&nbsp;<ShuffleIcon/></button>
                </section>
                <section className="tools">
                    <button id="orientation-btn" className="btn icon" title="card orientation" onClick={toggleOrientation}><OrientationIcon/></button>
                    <button id="refresh-btn" className="btn icon" title="refresh reading" onClick={refreshReading}><RefreshIcon/></button>
                   
                </section>
            </ReadingMenu>
            <Deck deck={newDeck} shuffleBtn={shuffleBtn} selectCard={selectCard} stack={reshuffle} styleId='deal-deck'/>
            <ReadingWindow id="reading-window_id" className='reading-window'>
                <section className="layout-section">
                    <Layout hand={hand} layout={layout} viewCard={viewCard}/>
                </section>
            </ReadingWindow>
        </ReadingWrapper>
    );
};

const ReadingMenu = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'left shuffle tools';
    align-items: center;
    justify-content: center;
    gap: 6px;
    padding: 8px 40px 2px;
    width: calc(100% - 80px);
    
    > section {
        display: flex;
    }
    > section.blank {
        justify-content: flex-start;
        grid-area: left;
    }
    > section.shuffle {
        justify-content: center;
        grid-area: shuffle;
    }
    > section.tools {
        justify-content: flex-end;
        grid-area: tools;
    }
    @media (max-width: 649px){
            display: grid;
            grid-template-columns: 1fr 80px;
            grid-template-areas: 'shuffle tools';
            padding: 8px 5vw 2px;
            width: 90vw;
        > section.shuffle {
            justify-content: start;
        }
        > section.blank {
            display: none;
        }
    }

    .btn {
        border: 2px solid var(--color-hex-darkgrey);
        color: var(--color-hex-grey);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3px 32px;
        transition: border 1s ease;
    }
`;
const ReadingWindow = styled.div`
    gap: 20px;
    width: 100%;
    height: calc(100dvh - 200px);
    max-width: 1200px;
    margin: 0 auto;
    @media (orientation: portrait) and (max-width: 1200px) {
        height: calc(100dvh - 135px);
        width: 100%;
    }

    h1 {
        border-bottom: 1px solid black;
        padding-bottom: 8px;
        margin-bottom: 8px;
        font-size: 24px;
    }
    h3 {
        padding-bottom: 12px;
        font-size: 20px;
    }
    h4 {
        padding-bottom: 8px;
        font-size: 16px;
    }

    p,
    ul {
        font-size: 14px;
    }
    p {
        padding-left: 25px;
        padding-bottom: 8px;
    }
    ul {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        padding-inline-start: 25px;
    }
    li {
        margin-right: 3px;
    }



    .layout-section {
        height: 100%;
    }
    @media (orientation: portrait) and (max-width: 1200px) {
        .layout-section {
            padding: 0px 10px;
        }
    }
`;
const ReadingWrapper = styled.div`
    position: fixed;
    width: 100vw;

    header {
        display: grid;
        grid-template-columns: 1fr 240px 1fr;
        grid-template-areas: 'title shuffle buttons';
    }
    @media (max-width: 749px) {
        header {
            grid-template-columns: 100%;
            grid-template-rows: auto 10px 38px;
            grid-template-areas: 'title' 'buttons' 'shuffle';
        }
    }

    header > section {
        height: auto;
        display: flex;
        align-items: center;
    }
    header > section:first-of-type {
        grid-area: title;
        justify-content: left;
    }
    header > section:nth-of-type(2) {
        grid-area: shuffle;
        justify-content: center;
    }
    header > section:nth-of-type(3) {
        grid-area: buttons;
        justify-content: right;
        overflow: hidden;
    }
    @media (max-width: 749px) {
        header > section:first-of-type {
            height: 26px;
        }
        header > section:nth-of-type(3) {
            width: 100%;
            justify-content: center;
        }
    }


    .linear-gradient stop {
        transition: .6s ease;
    }
    #orientation-btn {
        cursor: ns-resize;
    }
    #orientation-btn.toggle-orientation-btn {
        cursor: n-resize;
    }
    #refresh-btn {
        cursor: alias;
    }

    /* gradient color styles for svg icons */
    #shuffle-gradient stop:last-of-type {
        stop-color: var(--color-hex-black);
    }
    #shuffle-gradient stop:first-of-type {
        stop-color: var(--color-hex-lightgrey);
    }
    .linear-gradient stop:first-of-type,
    #orientation-gradient_1 stop:last-of-type,
    #orientation-gradient_2 stop:first-of-type,
    .btn:hover #shuffle-gradient stop:last-of-type {
        stop-color: var(--color-hex-darkgold)!important;
    }
    .linear-gradient stop:last-of-type,
    #orientation-gradient_1 stop:first-of-type,
    #orientation-gradient_2 stop:last-of-type ,
    .btn:hover #shuffle-gradient stop:first-of-type {
        stop-color: var(--color-hex-lightgold)!important;
    }

    .toggle-orientation-btn #orientation-gradient_2 stop:first-of-type {
        stop-color: var(--color-hex-black)!important;
    }
    .toggle-orientation-btn #orientation-gradient_2 stop:last-of-type {
        stop-color: var(--color-hex-lightgrey)!important;
    }
`;


export default Reading;