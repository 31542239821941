//=> Create Layout display
import React        from 'react';
import styled       from 'styled-components';
import Card         from '../card/Card';

import './layouts.css';

const Layout = ({hand, layout, viewCard}) => {

    const showHand = hand.map((card, key) => {
        // const img = card.title.toLowerCase().split(' ').join('_');

        return (
            <div key={key} id={key} onClick={(e) => viewCard(e, card, layout.order[key])}>
                <Card card={card} />
                {/* <img className="card-face" style={{transform: `rotate(${180*card.rotation + 'deg'})`}} src={`/cards/${img}.jpg`} alt={`${data.title} - ${layout.name}`}/> */}
            </div>
        );
    });

    return(
        <Wrapper id="show-layout-container">     
            <div className={`${layout.type}`}> 
                <section className="spread-section">
                    {showHand}
                </section>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    
    height: calc(100dvh - 240px);
    @media (orientation: portrait) and (max-width: 1200px) {
        height: auto;
    }
`;

export default Layout;