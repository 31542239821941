import React from 'react';
import styled from 'styled-components';

// import './dropdown.css';

//==> Flexible Options Menu
//======>> PROPS:
//=============>> defaultText           default option text.
//=============>> defaulValue           default option value.
//=============>> list                  list of options.
//=============>> defaultOption         Boolean to remove default option after selection.
//=============>> selectFucntion        function called with onchange event.
//=============>> selectedData          pass data from selection to signal removal of default option.

const Dropdown = ({defaultText, defaultValue, defaultOption, list, selectFunction, selectedData}) => {

    const showList = list.map((item, key) => {
        return (<option id={item.id} value={item.id} key={key}>{item.name}</option>);
    });

    return (
        <select className="dropdown" onChange={selectFunction}>
            { (!selectedData.id  && defaultOption) && <option id="dropdown-default" value={defaultValue}>{defaultText}</option> }
            {showList}
        </select>
    );
};

const SelectWrapper = styled.select`
    text-transform: capitalize;
    border: 1px solid var(--color-hex-lightgold);
`;

export default Dropdown;