import React from 'react';
import styled from 'styled-components';

import Card from './Card';
import { useLocation } from 'react-router-dom';

const CardInfo = ({card, placement, orientation}) => {

    let location = useLocation().pathname.replace('/','');
    if (!location) {
        location = 'home';
    };

    const iconStyle = 'script'
    const img = card.title.toLowerCase().split(' ').join('_');

    const mapAstro = card.astro.map((item, i) => {
      const length = card.astro.length;
      
      // if there is only one, return item.
      if ( length === 1 ){
        return (<span key={i}>{item}</span>)
      }
      //==> add "," and "&" to multiple
      if ( length > 1 ) { 
        if ( i < length - 1 ){
          return (<span key={i}>{item}, </span>)
        }
        if ( i === length - 1 ){
          return (<span key={i}>{item} & </span>)
        }
        if ( i === length ){
          return (<span key={i}>{item}</span>)
        }
      }

    });

    const mapElement = card.element.map((item, i) => {
      const length = card.element.length;
      //==> add "|" to following items
        if ( i === 0 ){
            return (<span key={i} id={`${item}`}>
                <img src={`/svg/${iconStyle}/${item}_${iconStyle}.svg`} alt={`${item} element`} width="24px" height="24px" />
            </span>);
        }
        if ( i > 0  ){
            return (<span key={i} id={`${item}`}>
                &nbsp;|&nbsp;<img src={`/svg/${iconStyle}/${item}_${iconStyle}.svg`} alt={`${item} element`} width="24px" height="24px" />
            </span>);
        }
    });

    // const mapSubElement = card.subelem.map((item, i) => {
    //   const length = card.subelem.length;
      
    //   // if there is only one, return item.
    //   if ( length === 1 ){
    //     return (<span key={i}>{item}</span>)
    //   }
    //   //==> add "," and "&" to multiple
    //   if ( length > 1 ) { 
    //     if ( i < length - 1 ){
    //       return (<span key={i}>{item}, </span>)
    //     }
    //     if ( i === length - 1 ){
    //       return (<span key={i}>{item} & </span>)
    //     }
    //     if ( i === length ){
    //       return (<span key={i}>{item}</span>)
    //     }
    //   }
    // });
    
    const mapIcons = card.icons.map((item, i) => {
      if(item){
        return (<img className="element-icon" src={`/svg/${iconStyle}/${item}_${iconStyle}.svg`} alt={`${item} element`} width="24px" height="24px"/>);
      }
    });

    const mapMajor = card.major.map((item, i) => {
      const length = card.major.length;
      
      // if there is only one, return item.
      if ( length === 1 ){
        return (<span key={i}>{item}</span>)
      }
      //==> add "," and "&" to multiple
      if ( length > 1 ) { 
        if ( i < length - 2 ){
          return (<span key={i}>{item}, </span>)
        }
        if ( i === length - 2 ){
          return (<span key={i}>{item} & </span>)
        }
        if ( i === length - 1 ){
          return (<span key={i}>{item}</span>)
        }
      }
    });

    const mapSubastro = card.subastro.map((item, i) => {
      return (<span key={i}>{item}</span>);
    });
    // const mapSeph = card.seph.map((item, i) => {
    //   return (<span key={i}>{item}</span>);
    // });
    const mapKeys = card.keys.map((item, i) => {
      return (<li key={i}>{item}</li>);
    });
    const mapRevKeys = card.revkeys.map((item, i) => {
      return (<li key={i}>{item}</li>);
    });
    const mapDesc = card.desc.map((item, i) => {
      return (<p key={i}>{item}</p>);
    });
    const mapRevDesc = card.revdesc.map((item, i) => {
      return (<p key={i}>{item}</p>);
    });

    return(
        <Wrapper className={`card-info grid ${location}`}>
            <section className="card-section">
                { placement && 
                    <div className="placement-info mobile-only">
                        <h3>Card Position:</h3>
                        <h4>{placement.title}</h4>
                        <p>{placement.description}</p>
                        <p>{placement.prompt}</p>
                    </div>
                }
                <Card card={card} orientation={orientation}/>
            </section>

            <section className="info-section">
                { placement && 
                    <div className="placement-info desktop-only">
                        <br/>
                        <h3>Card Position:</h3>
                        <h4>{placement.title}</h4>
                        <p>{placement.description}</p>
                        <p>{placement.prompt}</p>
                    </div>
                }
                <div className="header">
                { card.title.length > 0 && <h1>{card.title}</h1> }
                <h2>
                    { card.element.length > 0 && <span className="element icons">{mapElement}</span> }
                    { card.subtitle.length > 0 && <span className="subtitle">{card.subtitle}</span> }
                    { card.icons.length > 0 && <span className="icons">{mapIcons}</span> }
                </h2>
                {/* { card.subelem.length > 0  && <span className="subelements">{mapSubElement}</span> } */}
                </div>
                <div>
                    { card.major.length > 0 && <p className="major-arcana">{mapMajor}</p> }
                    { card.seph.length > 0 && <p className="seph">{card.seph}</p> }
                    { card.astro.length > 0 && <p className="astro-p">Ruled by {mapAstro}</p>}
                    { card.subastro.length > 0 && <ul className="subastro">{mapSubastro}</ul> }
                </div>

                {/* If length is not greater than 0 STOP */}
                {/* If location is 'reading' show only orientation selected */}
                {/* If location is not 'reading' show both lists */}

                { (card.keys.length > 0 && location !== 'reading') &&
                    <div className="attributes">
                        <h3>Upright Attributes:</h3>
                        <ul className="two-col-ul">{mapKeys}</ul>
                    </div>
                }

                { (card.revkeys.length > 0 && location !== 'reading') &&
                    <div className="attributes">
                        <h3>Reversed Attributes:</h3>
                        <ul className="two-col-ul">{mapRevKeys}</ul>
                    </div>
                }

                {/* Reading specific orientation descriptions */} 
                { (location === 'reading') &&
                    <div className="attributes">
                        <h3>Attributes:</h3>
                        { (card.keys.length > 0 && location === 'reading' && card.rotation !== 1) &&
                            <ul className="two-col-ul">{mapKeys}</ul>
                        }
                        { (card.revkeys.length > 0 && location === 'reading' && card.rotation === 1 && orientation === true) &&
                            <ul className="two-col-ul">{mapKeys}</ul>
                        }
                        { (card.revkeys.length > 0 && location === 'reading' && card.rotation === 1 && orientation === false) &&
                            <ul className="two-col-ul">{mapRevKeys}</ul>
                        }
                    </div>
                }
                <div>
                    { card.desc.length > 0 && <section>{mapDesc}</section> }
                    { card.revdesc.length > 0 && <section>{mapRevDesc}</section> }
                </div>
            </section>

        </Wrapper>
    );
};

const Wrapper = styled.div`
    --base-padding: 40px;
    --height-multiple: 3;
    --width-multiple: 3;
        @media (max-width: 749px) {
            --base-padding: 32px;
            --height-multiple: 3;
            --width-multiple: 1;
        }

    grid-template-columns: clamp(272px, 33.33%, 360px) 1fr;
    gap: var(--base-padding);
        @media (max-width: 749px) {
            grid-template-columns: 1fr;
        }
        
    height: calc(100% - (var(--base-padding) * var(--height-multiple)));
    width: calc(100% - (var(--base-padding) * var(--width-multiple)));
    max-width: 100%;
    padding: calc(var(--base-padding) * var(--height-multiple)) calc(var(--base-padding) * var(--width-multiple));

    h1 {
        font-size: 32px;
        font-weight: 800;
    }
    h2 {
        font-size: 21px;
        font-weight: 300;
    }
    h3 {
        font-weight: 500;
        font-size: 18px;
    }
    p {
        margin-top: 12px;
        font-size: 16px;
        font-weight: 300;
        line-height: 140%;
    }
    ul {
        margin-top: 6px;
        font-size: 14px;
        font-weight: 300;
    }
    li {
        margin-top: 6px;
    }

    .two-col-ul {
        -moz-column-count: 2;
        -moz-column-gap: 32px;
        -webkit-column-count: 2;
        -webkit-column-gap: 32px;
        column-count: 2;
        column-gap: 32px;
    }

    .info-section .element {
        text-transform: capitalize;
    }

    span > img + img,
    span + span {
        margin-left: 4px;
    }

    .icons img {
        height: 15px;
        width: 15px;
    }

    .card-modal .subelements {
        font-size: 14px;
        font-weight: 300;
    }

    .placement-info {
        margin-bottom: 1rem;
    }

    .attributes {
        margin-top: 1rem;
    }

    .img-box {
        width: 50%;
        font-size: 0;
    }
    .card-section {
        align-items: flex-start;
        flex-direction: column;
    }

`;
export default CardInfo;