import React from 'react';


const AccountPage = () => {

    return (
        <>
            Account Page Test
        </>
    )
}



export default AccountPage;