import React, { useState, useEffect } from 'react';
import styled                         from 'styled-components';
import { Link }                       from 'react-router-dom';
import * as routes                    from '../constants/routes';

// import './DailyCard.css';
// import './FlipCard.css';

import FlipCard                       from './card/FlipCard';

 ////-- Generate random single card for DailyCard after clicking on DailyCard back image

const DailyCard = ({deck, viewCard}) => {
  const [card, setCard] = useState({
      default: true,
      id: 0,
      suit: ``,
      majorNum: ``,
      title: ``,
      subtitle: ``,
      element: [],
      subelem: [],
      astro: [],
      subastro: [],
      seph: ``,
      role: ``,
      keys: [],
      revkeys: [],           
      desc: [],
      revdesc: [],
      major: [],
  });

  //-- need to add show-card className 
  const pickRandomCard = (e) => {
      e.preventDefault();
      if(card.default && deck.length > 1){
          let getDeck = [...deck];
          let shuffledDeck = [];
          while (getDeck.length > 0) {
              let index = Math.floor(Math.random() * getDeck.length);
              let card = getDeck[index];
              shuffledDeck.push(card);
              getDeck.splice(index, 1);
          };
          setCard(shuffledDeck[0]);
          document.getElementById('dailycard_id').classList.add('show-card');
      };
  };

  const Content = () => {
    return (
      <div>
        <h2 className="title">Tarotfied</h2>
        <h4 className="subtitle">"A question never asked is never answered."</h4>
        <p className="type">We come to these places in search of answers we already have.  It's only a matter of opening yourself up to seeing the signs right in front of you.</p>
        <p className="type">Explore our variety of spreads and have fun.</p>
        <br/>
        <Link to={routes.READ}><h4 className="cta">Start Reading ➛</h4></Link>
      </div>
    );
  };

  return (
    <Wrapper>
      <div className="dailycard wrapper">
        <img className="background-img cursor" src="./backgrounds/watercolor-background.png" alt="waterpaint wallpaper"/>
        <div className="container">

          <div className="grid">
            <section className={(card.default && deck.length > 1) && "live"}>
              <FlipCard card={card} pickRandomCard={pickRandomCard} viewCard={viewCard}/>
            </section>
            <section className="desktop-only desktop-content">
              <Content/>
            </section>
          </div>
          
        </div>
      </div>

      <div className="mobile-only dailycard-mobile-content dark-pallet wrapper">
        <Content/>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
.dailycard.wrapper {
  width: 100%;
  margin: 0 auto;
  height: calc(100dvh - 60px);
  position: relative;
}
.dailycard .container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-self: center;
}

@media (min-width: 640px){
  .dailycard .title {
    padding-top: 1.5rem;
  }
}


.dailycard .background-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left;
}

.dailycard .grid {
  margin: 80px auto;
}
/* .dailycard .grid > section {  } */
.dailycard .grid > section:first-of-type {
  align-items: center;
}
.dailycard .grid > section:last-of-type {
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: calc(100% * 4/45);
}

.dailycard .grid > section p {
  padding: 0 10%;
}
@media (min-width: 640px){
  .dailycard .grid > section:first-of-type {
    justify-content: flex-end;
    padding-right: calc(100% * 4/45);
  }
}
@media (max-width: 639px){
  .dailycard .grid {
    margin: 80px auto;
    grid-template-columns: 1fr;
    height: auto;
  }
  .dailycard .grid > section:first-of-type {
    justify-content: center;
  }
}


.dailycard-mobile-content {
  width: 80vw;
  padding: 80px 10vw;
}

.dailycard-mobile-content p {
  padding-left: 5vw;
}









/* .dailycard {
    width: 100%;
}

.dailycard-container {
  max-width: 1000px;
  margin: 40px auto;
}

.dailycard-container > div:first-of-type {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dailycard-container > div:last-of-type {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.dailycard-container ul {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  padding-inline-start: 25px;
}
 */
`;

export default DailyCard;