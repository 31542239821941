import React        from 'react';

import DailyCard    from '../../components/DailyCard';

const HomePage = ({deck, layouts, viewCard}) => {

    return (
        <div id="home-page_id">
            <DailyCard 
                deck={deck} 
                layouts={layouts}
                viewCard={viewCard}
            />
        </div>
    );
};

export default HomePage;