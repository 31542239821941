import React from 'react';
import styled from 'styled-components';

// import './FlipCard.css';

////-- Flipable Daily Card
const FlipCard = ({card, pickRandomCard, viewCard}) => {

    return (
        <Wrapper id="dailycard_id" className="dailycard flip-card">
            <div className="flip-card-inner" onClick={e => pickRandomCard(e)}>
                <div className="flip-card-back">
                    <img src="./card-back.png" alt="back of card"/>
                    <h2>Tap Card</h2>
                </div>
                <div className="flip-card-face card-face" onClick={(e) => viewCard(e, card)}>
                    <img src={`/cards/${card.title.toLowerCase().split(' ').join('_')}.jpg`} alt={`${card.title}`}/>
                </div>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div`
@media only screen and (min-width: 640px){
      --img-width: 268px;
  }
  @media only screen and (max-width: 639px){
      --img-width: 90vw;
      --img-max-width: 320px;
  }
    background-color: transparent;
    width: calc( var(--img-width) * 1);
    height: calc( var(--img-width) * 1.72);
    max-width: calc( var(--img-max-width) * 1);
    max-height: calc( var(--img-max-width) * 1.72);
    perspective: 1000px;
  
    &.show-card .flip-card-inner {
        transform: rotateY(180deg);
      }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    h2 {
        position: absolute;
        top: 20%;
        left: 0;
        color: white;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);
    }
    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
      }
      

        
      .flip-card-back, .flip-card-face {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        top: 0;
        border-radius: 16px;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      }
      
      .flip-card-back {
        color: black;
      }
    
      .flip-card-face {
        color: white;
        transform: rotateY(180deg);
        cursor: help!important;
      }
`; 

export default FlipCard;