//=> Create Deck to be used in Draw 23/44
import React, { useState, useEffect }   from 'react';
import styled from 'styled-components';

import Card from '../card/Card';

//=> Goal: Need to remove stack-deck classname from default and add only use when shuffling
// Start by removing stack deck from div class mapper
// Or pass a setting that only includes stack-deck at the start or reshuffle.

const Deck = ({deck, selectCard, viewCard, stack, styleId}) => {

    const mapDeck = deck.map((card, key) => {
        const img = card.title.toLowerCase().split(' ').join('_');
        const stackSwitch = stack ? " stack-deck" : ' splay-deck';
        return (
            <div 
              key={key} 
              id={card.id} 
              className={`card-dim dealt-card cursor-help${stackSwitch}`}
              onClick={(e) => {(styleId === `deal-deck`) ? selectCard(e) : viewCard(e, card);}}>
                { (styleId === `deal-deck`) && <img src='/card-back.png' alt="Star Card Back" /> }
                <Card card={card} />
            </div>
        );
    });
    return(
        <DeckWrapper id={`${styleId}`} className="deck-container">
            <div className="deck">
                <div className="cards">
                    {mapDeck}
                    { (styleId === `deal-deck`) &&
                        <img id="top-back-card" className="card-dim" src='/card-back.png' alt="Star Card Back" onClick={(e) => {selectCard(e)}}/>
                    }
                </div>
            </div>
        </DeckWrapper>
    );
};

const DeckWrapper = styled.div`
.deck {
    --percentage: .337;
    --hover-percentage: calc(var(--percentage) * 1.1);
    --card-shadow: 5px;
    --adjustment: 44px;
    --card-margin: calc(var(--card-width) - var(--adjustment));
    --deck-count: 78;
}
@media only screen and (max-width: 749px) {
  :root {
    --percentage: .2;
    --hover-percentage: calc(var(--percentage) * 1.2);
    --adjustment: 72px;
  }
}

  /***** Card Dimensions: 23/40 */
  .card-dim {
    height: calc(var(--card-height) * var(--percentage));
    width: calc(var(--card-width) * var(--percentage));
    display: inline-block;
    overflow: hidden;
    border-radius: calc(16px * var(--percentage));
    box-shadow: calc(-5px * var(--percentage)) calc(5px * var(--percentage)) calc(5px * var(--percentage)) rgba(0 0 0 / 12.5%);
    position: relative;
    // transition: .1s ease;
    transition: all 2s;
    &:hover {
      box-shadow: calc(-5px * var(--percentage)) calc(5px * var(--percentage)) calc(5px * var(--percentage)) rgba(0 0 0 / 50%);
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &#deal-deck {
    max-height: calc(var(--card-height) * var(--hover-percentage));
    max-width: 1200px;
    margin: 0 auto;
    .deck {
      max-width: 100vw;
      width: 100vw;
      overflow-x: scroll;
      position: relative;
      height: calc(var(--card-height) * var(--hover-percentage));
      padding-bottom: calc(15px * var(--hover-percentage));
    }
    .cards {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: calc(var(--deck-count) * var(--adjustment) * var(--percentage));
      height: calc(var(--card-height) * var(--hover-percentage));
      padding-bottom: calc(15px * var(--hover-percentage));
    }
    .card-dim {
      cursor: pointer!important;
    }
    .card-dim:hover {
      --percentage: var(--hover-percentage);
      z-index: 8;
      cursor: pointer;
    }
    .card-dim img + img {
      position: absolute;
      z-index: 4;
      top: 0;
    }  

    /* Hide the whole card */
    .stack-deck {
      margin-right: calc(-1 * var(--card-width) * var(--percentage));
      transition: all 2s;
    }
    /* Show some of the card */
    .splay-deck {
      margin-right: calc(-1 * var(--card-margin) * var(--percentage));
      transition: all .4s ease;
    }
  }

  &#index-deck {
    --percentage: .6;

    max-width: 900px;
    margin: 20px auto;

    .cards {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
`;
export default Deck;