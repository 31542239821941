import React from 'react';
import styled from 'styled-components';
import Hamburger from '../nav/hamburger';

import  CardInfo from './CardInfo';
const CardModal = ({card, closeCardModal, placement, orientation}) => {

    return(
      <CardModalWrapper className="cardmodal modal">
        <div className="modal-container">

          <div className="close-modal-box">
            <Hamburger hamburgerFunction={closeCardModal} hamburgerStyle='hamburger-close'/>
          </div>
            <CardInfo card={card} placement={placement} orientation={orientation}/>
        </div>
      </CardModalWrapper>  
    );
};

const CardModalWrapper = styled.div`

@media (min-width: 640px){
  .modal-container {
    height: calc(100dvh - 40px);
    width: calc(100vw - 40px);
    margin: 20px;
    overflow: hidden;
  }
}

.modal-container .info-section {
  overflow: scroll;
  padding-bottom: 80px;
  flex-direction: column;
}
@media (max-width: 749px) {
  .modal-container {
    overflow: scroll;
  }
  .grid {
    grid-template-columns: 1fr;
  }
  .modal-container .info-section {
    overflow: inherit;
    padding-bottom: 80px;
    height: auto;
  }
}



`;

export default CardModal;