import React                from 'react';
import Deck                 from '../../components/deck';

const Library = ({deck, viewCard}) => {

    
    return (<div>
            <Deck deck={deck} styleId='index-deck' viewCard={viewCard}/>
    </div>);
};
export default Library;