import React  from 'react';
import styled from 'styled-components';

////=> <Card card={card} layout={layout} orientation={orientation}/>
const Card = ({card, layout, orientation}) => {

    const title = card.title.toLowerCase().split(' ').join('_');

    const position = layout ? layout.name : card.id;
    const rotation = orientation ? 0 : card.rotation;
        
    return(
        <Img 
            className="card-img" 
            style={{transform: `rotate(${180*rotation + 'deg'})`}} 
            src={`/cards/${title}.jpg`} 
            alt={`${position}: ${card.title}`}
            />
    );
};

const Img = styled.img`
    width: 100%;
    height: auto;
    object-fit: cover;
`;

export default Card;