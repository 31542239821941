//=> Activate component to update collection
//=> Must delete previous doc in deck collection
import React, { useState, useEffect }   from 'react';
import fs                               from '../../../firebase/config';
import { addDoc, collection }           from 'firebase/firestore'; 

const Update = () => {
    const [ deck, setDeck] = useState([]);
    const zodiac = [{
        name: `Aries`,    
        symbol: `♈`
    },{
        name: `Leo`,    
        symbol: `♌`
    },{
        name: `Saggitarius`,
        symbol: `♐`
    },{
        name: `Cancer`,
        symbol: `♋`
    },{
        name: `Scorpio`,
        symbol: `♏`
    },{
        name: `Pisces`,
        symbol: `♓`
    },{
        name: `Libra`,
        symbol: `♎`
    },{
        name: `Aquarius`,
        symbol: `♒`
    },{
        name: `Gemini`,
        symbol: `♊`
    },{
        name: `Capricorn`,
        symbol: `♑`
    },{
        name: `Taurus`,
        symbol: `♉`
    },{
        name: `Virgo`,
        symbol: `♍`
    }];
    const planets = [{
        name: `Mercury`,
        symbol: `☿`
    },{
        name: `Venus`,
        symbol: `♀`
    },{
        name: `Earth`,
        symbol: `♁`
    },{
        name: `Mars`,
        symbol: `♂`
    },{
        name: `Jupiter`,
        symbol: `♃`
    },{
        name: `Saturn`,
        symbol: `♄`
    },{
        name: `Uranus`,
        symbol: `♅`
    },{
        name: `Neptune`,
        symbol: `♆`
    }];
    const alchemical = [{
        name: `earth`,
        symbol: `🜃`
    },{
        name: `air`,
        symbol: `🜁`
    },{
        name: `fire`,
        symbol: `🜂`
    },{
        name: `water`,
        symbol: `🜄`
    }];

useEffect(() => {
    setDeck(prevState => (
        [{
            id: 0,
            suit: `major`,
            majorNum: `0`,
            title: `The Fool`,
            subtitle: `Power of Potential`,
            icons: [`♅`,`♒︎`],
            element: [],
            subelem: [],
            astro: [`Uranus`],
            subastro: [`Uranus rules the sign of Aquarius`],
            seph: [`The Fool walks the first Tree of Life pathway, connecting Kether with Chokmah`],
            role: ``,
            keys: [`innocence`, `inexperience`, `seeking experience`, `openness`, `lacking guile`, `pure potential`, `travel`, `apprenticeship`, `a life altering journey`, `school of life`, `learning through experience`, `coming into adulthood`],
            revkeys: [`ignorance`, `willful blindness`, `misplaced trust`, `limited potential`, `inexperience`, `failure to learn lessons`, `resistance to change`, `choice or failure to heed warnings`, `canceled or delayed travel plans`],          
            desc: [],
            revdesc: [],
            major: [`The Fool represents each soul's journey through the world over multiple lifetimes. The Fool sets out to exchange innocence for wisdom through experience. If The Fool can successfully navigate the 21 stations of the major arcana he will return to spirit as an enlightened sage or ascended master.`],
        },{
            id: 1,
            suit: `major`,
            majorNum: `I`,
            title: `The Magician`,
            subtitle: `Power of Will`,
            icons: [`☿`,`♊︎`,`♍︎`],
            element: [],
            subelem: [],
            astro: [`Mercury`],
            subastro: [`Mercury rules the signs of Gemini and Virgo`],
            seph: [`The Magician resides on the Tree of Life pathway connecting Kether with Binah`],
            role: [],
            keys: [`unity`, `balance`, `understanding the importance of balance`, `early or foundational education`, `learning to use available tools`, `wielding power`, `working within a system`, `directed use of will power`],
            revkeys: [`egocentric worldview`, `inflated sense of self`, `false confidence`, `poor utilization of available tools`, `unjust manipulation of a system`, `causing imbalance`, `attempting to master complexities without understanding the basics`],
            desc: [],
            revdesc: [],
            major: [`The Magician teaches The Fool how to direct his will, and influence his world. The Fool becomes acquainted with his tools and the foundational elements. He learns that he has power, begins to take charge of his life, and specialize his interests.`],
        },{
            id: 2,
            suit: `major`,
            majorNum: `II`,
            title: `The High Priestess`,
            subtitle: `Power of Higher Consciousness`,
            icons: [`☾`, `♋︎`],
            element: [],
            subelem: [],
            astro: [`the Moon`],
            subastro: [`The Moon rules Cancer`],
            seph: [`The High Priestess resides on the Tree of Life pathway, connecting Kether with Tiphareth`],
            role: [],
            keys: [`balanced forces`, `reflection`, `looking within`, `insight`, `doing inner work`, `hidden information`, `secrets`, `pursuit of deeper understanding`, `potential fertility and growth`, `duality`, `lunar cycles`],
            revkeys: [`power imbalance`, `blindness`, `deceit`, `shallow perspective`, `personal stagnation`, `lack of growth`, `resistance to change`, `looking for external enemies`, `illusions`, `avoiding reflection`],           
            desc: [],
            revdesc: [],          
            major: [`The High Priestess helps The Fool to see the dualities and nuances of the world and himself. He begins to practice spiritual discipline, allowing his high self to influence his thoughts and actions.`],
        },{
            id: 3,
            suit: `major`,
            majorNum: `III`,
            title: `The Empress`,
            subtitle: `Power of Divine Feminine`,
            icons: [`♀︎`, `♉︎`, `♎︎`],
            element: [],
            subelem: [],
            astro: [`Venus`],
            subastro: [`Venus rules Taurus and Libra`],
            seph: [`The Empress presides over the Tree of Life pathway connecting Chokmah with Binah`],
            role: [],
            keys: [`fertility`, `creativity`, `bounty`, `wealth`, `luxury`, `comfort`, `inspiration`, `breakthroughs`, `finding solutions`, `stability`, `joyful productivity`, `growth`, `harmony`, `sensual pleasure`, `motherhood`, `divine femininity`, `the earth`],
            revkeys: [`Infertility`, `loss of a child`, `creative blocks`, `stagnation`, `decay`, `poor harvest`, `depleted natural resources`, `material loss`, `disharmony`, `war`, `destruction`, `famine`, `emptiness`, `choice to remain childless`, `harmful expression of femininity`, `earth in turmoil`],
            desc: [],
            revdesc: [],
            major: [`The Empress teaches The Fool to combine the skills of The Magician and High Priestess, conceiving new things and birthing them into being. In her realm The Fool perceives the interconnectedness of all things, value of beauty, and to take joy in life within and without.`],
        },{
            id: 4,
            suit: `major`,
            majorNum: `IV`,
            title: `The Emperor`,
            subtitle: `Power of Divine Masculine`,
            icons: [`♈`, `♂`],
            element: [],
            subelem: [],
            astro: [`Aries`],
            subastro: [`Aries is ruled by Mars`],
            seph: [`The Emperor presides over the Tree of Life pathway connecting Chokmah with Tiphareth`],
            role: [],
            keys: [`order`, `boundaries`, `authority`, `responsibility`, `set above the crowd`, `sexual potency`, `self control`, `benevolent leader`, `stern but fair`, `protective`, `pragmatic`, `clear minded`, `direct`, `decisive`, `aggressive style`, `battle plan`, `fatherhood`, `divine masculine`, `power`],
            revkeys: [`disorder`, `authoritarianism`, `abuse of power`, `lacking responsibility`, `irresolute will`, `foggy mind`, `failure to maintain healthy boundaries`, `invasion`, `selfish use of power`, `cruelty`, `severity`, `entitlement`, `poor planning`, `undue aggression`, `vanity`, `infertility`, `choice to remain childless`, `harmful expression of masculinity`, `disempowerment`],
            desc: [],
            revdesc: [],
            major: [`The Emperor teaches The Fool discipline and the value of order. The Fool comes to understand that power and responsibility cannot be effectively separated, and concepts of balance are reinforced especially between responsibility and desire.`],
        },{
            id: 5,
            suit: `major`,
            majorNum: `V`,
            title: `The Hierophant`,
            subtitle: `Power of Institution`,
            icons: [`♉`,`♀`],
            element: [],
            subelem: [],
            astro: [`Taurus`],
            subastro: [`Taurus is ruled by Venus`],
            seph: [`The Hierophant occupies the Tree of Life pathway connecting Chokmah with Chesed`],
            role: [],
            keys: [`societal order`, `hierarchy`, `institutional power`, `filtered truth`, `layers of authority`, `values presentation`, `values comfort`, `values ritual`, `slow to change, but values development and growth`, `bureaucracy`, `superficial understanding`, `mentorship`, `keeps community records and wisdom`, `attempts to unite people`, `organized effort`],
            revkeys: [`chaos or authoritarianism`, `corrupt leadership`, `sharing or withholding inappropriately`, `obscuring the truth`, `fears change`, `stifles healthy growth`, `discourages questions`, `opulence`, `facade hiding lack of substance`, `adherence to letter of the law vs spirit of the law`, `adherence to ritual without consideration of its meaning`, `loyalty to the institution vs the mission of the institution`],
            desc: [],
            revdesc: [],
            major: [`The Hierophant teaches The Fool about the usefulness of structure, and how proper application of law allows liberty to thrive. The Fool learns that higher levels of wisdom and power may only be rightly accessed through mastery of lower levels. In station five The Fool has reached the midpoint of his apprenticeship, and begins to take on responsibilities of mentorship in his own rite.`],
        },{
            id: 6,
            suit: `major`,
            majorNum: `VI`,
            title: `The Lovers`,
            subtitle: `Power of Communication`,
            icons: [`♊`,`☿`],
            element: [],
            subelem: [],
            astro: [`Gemini`],
            subastro: [`Gemini is ruled by Mercury`],
            seph: [`The Lovers reside on the Tree of Life pathway connecting Binah with Tiphareth`],
            role: [],
            keys: [`honest partnership`, `reunion`, `duality`, `a meeting of minds`, `cooperation of mind and heart`, `making choices`, `choosing wisely`, `communication`, `collaboration`, `harmonizing`, `unconditional love`, `idealism`, `knowing one’s self`, `accessing the high self through the subconscious`, `recognizing separateness as illusion`],
            revkeys: [`breaking partnership`, `conflict`, `communication breakdown`, `deception`, `despair`, `division`, `domination`, `codependence`, `manipulation`, `doubt`, `veneer of harmony`, `poor choices`, `lack of desirable options`, `need to rebalance head and heart`, `shutting out the high self`, `sense of isolation or separation`],
            desc: [],
            revdesc: [],
            major: [`The Lovers help The Fool to deepen his self understanding, and strengthen the connection with his high self when confronted with temptation. They also teach The Fool the value of varied perspective, and the personal growth that comes through collaboration and partnership.`],
        },{
            id: 7,
            suit: `major`,
            majorNum: `VII`,
            title: `The Chariot`,
            subtitle: `Power of Exploration`,
            icons: [`☾`,`♋`],
            element: [],
            subelem: [],
            astro: [`Cancer`],
            subastro: [`Cancer is ruled by the Moon`],
            seph: [`The Chariot rides along the Tree of Life pathway connecting Binah with Geburah`],
            role: [],
            keys: [`pushing boundaries`, `expanding horizons`, `exploration`, `travel`, `balance`, `stability allowing for creative expression`, `efficiency`, `pragmatism`, `self control`, `willpower`, `triumph`, `moving forward with plans`, `making progress`, `having vision and drive`, `curiosity`, `scientific understanding`, `protection of home and family`, `armor and bravado hiding a sensitive nature`],
            revkeys: [`restlessness`, `feeling stagnant`, `poor self control`, `defeat`, `not knowing how to move forward`, `lost direction`, `disrupted travel plans`, `lacking the necessary vision or drive`, `tempestuous emotions causing problems`, `closed mindedness`, `running from problems`, `chasing temptations`, `xenophobia`, `fearfulness leading to aggression`],
            desc: [],
            revdesc: [],
            major: [`Now that The Fool understands his true nature, may freely access his high self, and understands the form of his world through science he has the ability to drive The Chariot wherever he wishes to go. So long as he is the master of his will and desire The Chariot will serve him.`],
        },{
            id: 8,
            suit: `major`,
            majorNum: `VIII`,
            title: `Strength`,
            subtitle: `Power of Fortitude`,
            icons: [`☉`,`♌`],
            element: [],
            subelem: [],
            astro: [`Leo`],
            subastro: [`Leo is ruled by the Sun`],
            seph: [`Strength occupies the Tree of Life pathway connecting Chesed with Geburah`],
            role: [],
            keys: [`fortitude`, `facing fear`, `courage`, `stamina`, `determination`, `natural authority`, `leading with gentleness and compassion`, `passions and energies directed by the high self`, `magnetic personality`, `stability through balance`, `heritage`, `family ties`, `deep roots`, `sustainability`, `honesty`, `directness`, `valor`],
            revkeys: [`running from challenge`, `fearfulness`, `giving up`, `wearing out`, `discipline beyond usefulness`, `control through threat, force or browbeating`, `lack of authority`, `poor leadership`, `inflated ego`, `inflated self importance`, `lust`, `aggression`, `hunger for power`, `over estimation of power`, `righteousness for vanity’s sake`],
            desc: [],
            revdesc: [],
            major: [`Strength teaches The Fool that gentleness can be a stronger influence than force, that respect bears more authority than fear, and that true strength flows from spirit. The Fool discovers that when his high self is allowed to lead, superficial pleasure stops dominating his desires and discipline ceases to be an exhausting struggle.
            In stations 1-7 The Fool masters the world of form, in station 8 The Fool sets out to master non-form, from which form is shaped.`],
        },{
            id: 9,
            suit: `major`,
            majorNum: `IX`,
            title: `The Hermit`,
            subtitle: `Power of Dedication`,
            icons: [`♍`,`☿`],
            element: [],
            subelem: [],
            astro: [`Virgo`],
            subastro: [`Virgo is ruled by Mercury`],
            seph: [`The Hermit watches over the Tree of Life pathway connecting Chesed with Tiphareth`],
            role: [],
            keys: [`closing of a chapter`, `completion`, `withdrawal`, `learning a key lesson`, `focus on study or meditation`, `hope`, `seeing the light`, `foresight`, `wisdom`, `discretion`, `steadfastness`, `beacon of light`, `a reliable guide`, `leap from knowing to understanding`, `peace in solitude`],
            revkeys: [`lost in the dark`, `lacking wisdom`, `lacking discretion and foresight`, `over sharing or foisting opinions on others`, `false teacher`, `unreliable guide`, `over estimation of understanding`, `denial of facts`, `refusal to look at or see the truth`, `clinging to the past`, `division`, `fractured sense of self`],
            desc: [],
            revdesc: [],
            major: [`In the 9th station of the major arcana The Fool takes time to rest and reflect on all that he has learned and achieved. He makes the leap from knowing to understanding, makes plans for the path ahead, and teaches those who wish to learn.`],
        },{
            id: 10,
            suit: `major`,
            majorNum: `X`,
            title: `Wheel of Fortune`,
            subtitle: `Power of Experience`,
            icons: [`♃`,`♐`,`♓`],
            element: [],
            subelem: [],
            astro: [`Jupiter`],
            subastro: [`Jupiter rules Sagittarius, and is the classical ruler of Pisces`],
            seph: [`The Wheel of Fortune occupies the Tree of Life pathway connecting Chesed with Netzach`],
            role: [],
            keys: [`completion`, `wholeness`, `forward movement`, `certainty of change`, `need to adapt`, `applying past experience`, `success`, `luck`, `fortune vs responsibility`, `forces outside personal control`, `choices shaping the journey`, `embracing the moment`, `regeneration`, `cyclical nature of the world`, `durability`],
            revkeys: [`Feeling stuck`, `need to wait`, `irresponsibility`, `misfortune`, `set backs`, `resting on laurels`, `frozen by past trauma`, `avoiding responsibility for one’s choices`, `blindness to coming change`, `repeating the same lessons`, `exhaustion`],
            desc: [],
            revdesc: [],
            major: [`The Fool has completed his apprenticeship, gaining independence and taking responsibility for his own fortune. He has experience on which to inform his next venture. He has everything he needs to be successful, and is in a position of expanded opportunity with open potential. The Fool understands that he may not always be able to control his circumstances, but remains responsible for his own choices and attitude.`],
        },{
            id: 11,
            suit: `major`,
            majorNum: `XI`,
            title: `Justice`,
            subtitle: `Power of Balance`,
            icons: [`♎`,`♀`],
            element: [],
            subelem: [],
            astro: [`Libra`],
            subastro: [`Libra is ruled by Venus`],
            seph: [`Justice presides over the Tree of Life pathway connecting Geburah with Tiphareth`],
            role: [],
            keys: [`balancing law and liberty`, `fairness`, `balanced influences`, `a favorable judgment`, `equanimity`, `a neutral third party`, `using sound judgment`, `involvement with the legal system`, `working within the law`, `idealism`, `striving for a utopian vision`],
            revkeys: [`oppression`, `unfairness`, `slanted viewpoint or influence`, `unfavorable judgment`, `at the mercy of a biased judge`, `poor judgment`, `criminality`, `manipulation of the rules`, `score keeping`, `being on defense`, `gain at another’s expense`, `upholding unjust power structures`],
            desc: [],
            revdesc: [],
            major: [`Having learned inner balance during his apprenticeship The Fool is called to assist in balancing his community. Knowing right from wrong endows him with a higher level of responsibility for his choices. He practices dispelling untruth and leaving behind what does not serve. The Fool revisits concepts of balance and mediation.`],
        },{
            id: 12,
            suit: `major`,
            majorNum: `XII`,
            title: `The Hanged Man`,
            subtitle: `Power of Inner Peace`,
            icons: [`♆`,`♓`],
            element: [],
            subelem: [],
            astro: [`Neptune`],
            subastro: [`Neptune rules Pisces`],
            seph: [`The Hanged Man occupies the Tree of Life pathway connecting Geburah with Hod`],
            role: [],
            keys: [`transformation through surrender`, `acceptance`, `releasing the illusion of control`, `taking a pause`, `suspended animation`, `disengagement`, `alternate viewpoint`, `connection not attachment`, `meditation`, `ideals of balance and justice supersede personal considerations`],
            revkeys: [`suffering stemming from resistance`, `struggle for control`, `entrenchment in a particular perspective`, `fighting reality`, `forcing action`, `forcing plans through`, `attachment to an idea, circumstance or outcome`, `placing personal considerations over ideals of balance and justice`],
            desc: [],
            revdesc: [],
            major: [`The Hanged Man helps The Fool to master the concepts he learned with Justice. The Fool learns to alter his perspective, seeing things from various sides, and can no longer support injustice, even if it means upsetting things. He’s honest about his emotions, but does not allow them to rage unchecked. Guided by his high self, and knowing that discomfort and challenge may yield the greatest growth, The Fool is able to move through any situation with inner peace and grace.`],
        },{
            id: 13,
            suit: `major`,
            majorNum: `XIII`,
            title: `Death`,
            subtitle: `Power of Rebirth`,
            icons: [`♏`,`♇`,`♂`],
            element: [],
            subelem: [],
            astro: [`Scorpio`],
            subastro: [`Scorpio is ruled by Pluto, and by Mars in traditional astrology`],
            seph: [`Death occupies the Tree of Life pathway connecting Netzach with Tiphareth`],
            role: [],
            keys: [`transformation`, `meaningful and lasting change`, `cycle of life`, `something natural and unavoidable`, `the unknown`, `endings allowing for beginnings`, `initiation`, `renewal`, `release`, `sweeping personal, cultural or institutional shifts`, `power beyond human authority`, `equalizes all people`],
            revkeys: [`temporary or superficial change`, `decay`, `corruption`, `unnatural or harmful transition`, `disaster`, `wanton destruction`, `personal, cultural or institutional upheaval or disarray`, `reaching for power beyond rightful authority`, `seeking control beyond one’s scope`],
            desc: [],
            revdesc: [],
            major: [`In station 13 The Fool understands that Death is transformation not ending, and understanding dispels fear. Life is precious because it is fleeting. The Fool has become like a phoenix, experiencing death as the turning of a page, and may now travel between material and spiritual worlds without losing himself.`],
        },{
            id: 14,
            suit: `major`,
            majorNum: `XIV`,
            title: `Temperance`,
            subtitle: `Power of Alchemy`,
            icons: [`♐`,`♃`],
            element: [],
            subelem: [],
            astro: [`Sagittarius`],
            subastro: [`Sagittarius is ruled by Jupiter`],
            seph: [`Temperance occupies the Tree of Life pathway connecting Tiphareth with Yesod`],
            role: [],
            keys: [`balance`, `harmony`, `spiritual and material balance`, `strengthening`, `alchemy`, `blending and refining`, `acting wisely`, `giving form to thoughts`, `patient process`, `methodical`, `inspired and able`, `building on past achievements or ideas`, `bridging gaps`, `finding solutions`, `the middle path`],
            revkeys: [`disharmony`, `imbalance`, `imbalanced focus on either spiritual or material concerns`, `dominance`, `extremism`, `polarization`, `tribalism`, `fracturing`, `separation`, `using skill and power in service of greed and narcissism`],
            desc: [],
            revdesc: [],
            major: [`The Fool has achieved the balance, skill, and confidence to create whatever he conceives, birthing it perfectly into the material world with nothing lost in translation. He has mastered both form and non-form, completing a second cycle of seven. Now that The Fool holds the power and responsibility of creation he has only himself to fear.`],
        },{
            id: 15,
            suit: `major`,
            majorNum: `XV`,
            title: `The Devil`,
            subtitle: `Power of Materialism`,
            icons: [`♑`,`♄`],
            element: [],
            subelem: [],
            astro: [`Capricorn`],
            subastro: [`Capricorn is ruled by Saturn`],
            seph: [`The Devil sits on the Tree of Life pathway connecting Hod with Tiphareth`],
            role: [],
            keys: [`materialism`, `superficial success`, `half truths`, `demonizing`, `preying on fears`, `addiction`, `escapism`, `corruption`, `narcissism`, `concerns regarding self image and sense of worth`, `chasing status and power`, `denial of spiritual wisdom or reality`, `separating humanity from nature`, `perversion or demonization of nature based spiritual practice`, `practical concerns trumping spiritual or philosophical concerns`],
            revkeys: [`turning toward spirituality`, `reconnecting with nature`, `respect for the natural world`, `nature based spirituality`, `healing addiction`, `reining in egoic thought`, `delving beyond the surface`, `sacrificing status or luxury in favor of freedom`],
            desc: [],
            revdesc: [],
            major: [`The Fool has become a master of manifestation with the skill and power to materialize his visions and attract his desires. In station 15 The Fool is tempted to use his gifts for selfish gain or to be satisfied by superficial success.`],
        },{
            id: 16,
            suit: `major`,
            majorNum: `XVI`,
            title: `The Tower`,
            subtitle: `Power of Disruption`,
            icons: [`♂`,`♈`],
            element: [],
            subelem: [],
            astro: [`Mars`],
            subastro: [`Mars rules Aries`],
            seph: [`The Tower sits on the Tree of Life pathway connecting Netzach with Hod`],
            role: [],
            keys: [`a wake up call`, `disruption`, `crumbling structures and institutions (personal or societal)`, `break down of things thought to be strong and lasting`, `call to action`, `questioning foundational ideas`, `inspiration`, `sudden flash of insight or clarity`, `time of transition`, `refocusing attention`, `driving force`, `destruction making way for something new`],
            revkeys: [`gaining freedom at great cost`, `sacrifice in favor of growth or inspiration`, `resisting change that is outside one’s control`, `going on the attack`, `attack on position or beliefs`, `feeling under attack`, `falsehoods`, `paranoia`, `falling victim to misinformation`, `avoiding action`, `playing the victim`, `self pity`],
            desc: [],
            revdesc: [],
            major: [`The Fool has become a master of creation, but fallen into the trap of self importance, worldly success, and small mindedness. He is now roused from his revelries and reoriented to his higher calling.`],
        },{
            id: 17,
            suit: `major`,
            majorNum: `XVII`,
            title: `The Star`,
            subtitle: `Power of Love`,
            icons: [`♒`,`♈`,`♄`],
            element: [],
            subelem: [],
            astro: [`Aquarius`],
            subastro: [`Aquarius is ruled by Uranus, and by Saturn in traditional astrology`],
            seph: [`The Star shines on the Tree of Life pathway connecting Netzach with Yesod`],
            role: [],
            keys: [`fresh start`, `balanced living`, `guidance`, `inspiration`, `rejuvenation`, `openness`, `love freely given`, `allowing one’s light to shine`, `clarity`, `honesty`, `sharing gifts`, `generosity`, `living in harmony`, `deepening insight`, `interconnection`, `ease with one’s self`],
            revkeys: [`unbalanced attention`, `obsession`, `inconstant guide`, `simplistic point of view`, `rejecting or withholding gifts`, `hiding one’s light`, `avoidance`, `fear of exposure`, `disquiet`, `unrest`, `creative blocks`, `fear of honesty`, `holding onto old baggage`],
            desc: [],
            revdesc: [],
            major: [`The Star helps The Fool to come back to center, and reconnect with his true purpose following the upset of The Tower. Rejuvenated and inspired, The Fool prepares to continue his journey through the major arcana.`],
        },{
            id: 18,
            suit: `major`,
            majorNum: `XVIII`,
            title: `The Moon`,
            subtitle: `Power of Perception`,
            icons: [`♓`,`♆`,`♃`],
            element: [],
            subelem: [],
            astro: [`Pisces`],
            subastro: [`Pisces is ruled by Neptune, and by Jupiter in traditional astrology`],
            seph: [`The Moon shines on the Tree of Life pathway connecting Netzach with Malkuth`],
            role: [],
            keys: [`intuition`, `reflection`, `learning from past experience`, `developing perceptive abilities`, `developing psychic abilities`, `transition`, `accepting risk in favor of reward`, `growth through experience and vulnerability`, `illusions`, `dreams`, `imagination`, `honoring both wild and civilized sides of one’s self`, `collective consciousness`, `navigating through darkness`],
            revkeys: [`deception`, `lurking peril`, `hidden costs`, `living in a fantasy`, `illusions of safety and security`, `illusion of separateness`, `running wild or over fixation on properness`, `mimicking`, `surface level emotions overshadowing heart’s true desires`, `head in the sand`, `ignoring subtext`],
            desc: [],
            revdesc: [],
            major: [`The Fool has resumed his path of development, having mastered creation and attraction, he focuses on his abilities of perception. In the shadowy land of The Moon, The Fool faces his final test of ego as he is tempted to see himself as superior to others who have not achieved his level of attainment.`],
        },{
            id: 19,
            suit: `major`,
            majorNum: `XIX`,
            title: `The Sun`,
            subtitle: `Power of Light`,
            icons: [`☉`,`♌`],
            element: [],
            subelem: [],
            astro: [`the Sun`],
            subastro: [`Sun rules Leo`],
            seph: [`The Sun shines on the Tree of Life pathway connecting Hod with Yesod`],
            role: [],
            keys: [`successful endeavors`, `power`, `influence`, `ease in the presence of power`, `charisma`, `leadership abilities`, `vitality`, `fertility`, `enlightenment`, `openness`, `honesty`, `self control`, `direct style`, `freedom`, `growth`, `shining bright`, `Sun gods`],
            revkeys: [`material success`, `egocentrism`, `over intensity`, `need for directness or clarity`, `loss`, `recession`, `limited power`,` veneer of power`, `avoids leadership roles`, `fears exposure`, `dishonesty`, `trapped through privilege`],
            desc: [],
            revdesc: [],
            major: [`Once The Fool has successfully navigated the realm of The Moon he steps out into the land of The Sun where everything is illuminated and all life is able to thrive. He has become aligned to spirit in its highest form. Spiritual or moral practice has become automatic, no longer requiring conscious effort. The Fool has faced his fears, sees beyond death, and brought his desires in line with his ethics.`],
        },{
            id: 20,
            suit: `major`,
            majorNum: `XX`,
            title: `Judgment`,
            subtitle: `Power of Awakening`,
            icons: [`♇`,`♏`],
            element: [],
            subelem: [],
            astro: [`Pluto`],
            subastro: [`Pluto rules Scorpio`],
            seph: [`Judgment sits on the Tree of Life pathway connecting Hod with Malkuth`],
            role: [],
            keys: [`discernment`, `graduation`, `completing work`, `passing tests`, `expanded awareness`, `spreading knowledge`, `insight`, `ethical judgment`, `teaching`, `connection with spirit`, `rebirth, renewal`],
            revkeys: [`falling short of ethical ideals`, `denial of uncomfortable truth`, `failure to learn lessons`, `restricted growth`, `limited awareness`, `injustice`, `conceit`, `passing unfair judgment`],
            desc: [],
            revdesc: [],
            major: [`The Fool has stepped fully into the light, mastered himself, and knows the truth of his being. He sees himself as an interconnected part of nature. He knows that spirit runs through all things, and all things are united through spirit. The Fool now works to sound a wake up call and guide others on the path.`],
        },{
            id: 21,
            suit: `major`,
            majorNum: `XXI`,
            title: `The World`,
            subtitle: `Power of Unity`,
            icons: [`♄`,`♑`],
            element: [],
            subelem: [],
            astro: [`Saturn`],
            subastro: [`Saturn rules Capricorn`],
            seph: [`Connects Yesod with Malkuth`],
            role: [],
            keys: [`all encompassing`, `completion`, `unity`, `reunion`, `harmony`, `natural and human law`, `open possibilities`, `spiritual freedom`, `freedom within a system`, `understanding the rules`, `achieving goals`, `world travel`, `understanding the natural world`, `successful endeavors`],
            revkeys: [`limitation`, `restriction`, `confinement`, `lost balance`, `instability`, `ignorance of the law`, `misunderstanding the rules of the game`, `fixed system`, `stacking the odds`, `rebellion`, `egocentrism`, `isolation`],
            desc: [],
            revdesc: [],
            major: [`Having successfully navigated the major arcana and contributed to the growth of his community, The Fool returns to spirit as an enlightened sage. He may choose to exit the karmic wheel and cycles of incarnation if he wishes.`],
        },{
            id: 22,
            suit: `wands`,
            title: `Ace of Wands`,
            subtitle: `Power of Fire`,
            icons: [`♈♌♐`],
            element: [`Element of Fire`],
            subelem: [],
            astro: [`all fire signs`],
            subastro: [`Aries`, `Leo`, `Sagittarius`],
            seph: [`Corresponding with Kether, the first sephira is the source of consciousness and beginnings. In the Ace of Wands, Kether reminds us that spirit is the force that animates the physical world.`],
            role: [],
            keys: [`spark of life`, `passion`, `innovation`, `willpower`, `directed use of power and creativity`, `source of power`, `potential for growth`, `burning desire`, `pursuit of knowledge`, `a tool to build or destroy`, `projective/ masculine`],
            revkeys: [`creative blocks`, `diffused power`, `stagnation`, `lost passion`, `disenchantment`, `disappointment`, `waning strength`, `a tool to build or destroy`, `closed minded`, `inward focus`],
            desc: [],
            revdesc: [],
            major: [`The Ace of Wands is connected to The Magician as a one card, and each of the major arcana cards ruled by a fire sign; The Emperor (Aries), Strength (Leo), and Temperance (Sagittarius). `],
        },{
            id: 23,
            suit: `wands`,
            title: `Two of Wands`,
            subtitle: `Lord or Dominion`,
            icons: [`♂`,`♈`],
            element: [`Element of Fire`],
            subelem: [],
            astro: [`Mars in the 1st decan of Aries`],
            subastro: [`0-10° of Aries (March 21-30th) Mars at home. The Emperor on his throne.`],
            seph: [`Corresponding with Chokmah, the second sephira explores duality and choices. In the Two of Wands, Chokmah empowers each person  to choose wisely.`],
            role: [],
            keys: [`a personal decision`, `well positioned to make a choice`, `understanding both sides of an issue`, `at ease in a position of authority`, `owning one’s power`, `forming an agenda`, `learning to wield power effectively and wisely`, `relating to both sides of an issue`],
            revkeys: [`a difficult choice`, `lack of desirable options`, `oversimplification of an issue`, `discomfort with authority or power`, `avoiding responsibility`, `narrow viewpoint`],
            desc: [],
            revdesc: [],
            major: [`The Two of Wands is connected to The High Priestess as a two card, The Emperor as it is in the sign of Aries, and The Tower through its Martian influence`],
        },{
            id: 24,
            suit: `wands`,
            title: `Three of Wands`,
            subtitle: `Lord of Established Strength`,
            icons: [`☉`,`♈`],
            element: [`fire`],
            subelem: [],
            astro: [`Sun in the 2nd decan of Aries`],
            subastro: [`10-20° of Aries (March 31- April 10th) Sun exalted. Progenitor of life in the sign of beginnings and assertion.`],
            seph: [`Corresponding with Binah, the third sephira gives form to concepts and begins to shape plans. In the Three of Wands, Binah is able to make strong, strategic plans with the advantage of clarity and foresight.`],
            role: [],
            keys: [`a position of power or advantage`, `broadening experience`, `deepening wisdom`, `branching out`, `making plans`, `ideas taking shape`, `preparing for travel`, `giving and receiving`, `exchanging news`],
            revkeys: [`self limitation`, `imposed restrictions`, `lack of planning or over planning`, `ideas untried`, `lacking experience`, `homebound`, `lack of growth or unchecked growth`, `lose boundaries`],
            desc: [],
            revdesc: [],
            major: [`The Three of Wands is connected to The Empress as a three card, The Emperor as it is in the sign of Aries, and The Sun through the astrological influence of the sun.`],
        },{
            id: 25,
            suit: `wands`,
            title: `Four of Wands`,
            subtitle: `Lord of Perfected Work`,
            icons: [`♀`,`♈`],
            element: [`fire`],
            subelem: [],
            astro: [`Venus in the 3rd decan of Aries`],
            subastro: [`20-30° of Aries (April 11- 20th) Venus in detriment. The planet of love and attraction in the sign of initiation and courage.`],
            seph: [`Corresponding with Chesed, the fourth sephira preserves and protects what has been established. In the For of Wands, Chesed strengthens each individual through altruistic cooperation, and the preservation of communal heritage and culture.`],
            role: [],
            keys: [`rich tapestry of society`, `cultural history`, `coming together`, `organizing`, `partnership`, `marriage`, `social contract`, `stability through communal effort`, `harvest`, `reaping what has been sown`, `celebration`, `ritual`],
            revkeys: [`broken partnership`, `divorce`, `broken social contract`, `fractured community`, `skewed or altered history`, `poor harvest`, `outside influences`, `communal grief or trauma`, `emphasizing ritual over substance`],
            desc: [],
            revdesc: [],
            major: [`The Four of Wands is connected with The Emperor as a four card, and its position in the sign of Aries. It is connected to The Empress through its venusian influence.`],
        },{
            id: 26,
            suit: `wands`,
            title: `Five of Wands`,
            subtitle: `Lord of Strife`,
            icons: [`♄`,`♌`],
            element: [`fire`],
            subelem: [],
            astro: [`Saturn in the 1st decan of Leo`],
            subastro: [`0-10° of Leo (July 22- August 1st) Saturn, planet of boundaries, brings rules of engagement to the sign of personal power.`],
            seph: [`Corresponding with Geburah, the fifth sephira puts all that is to the test, and dissolves what no longer serves. In the Five of Wands, Geburah seeks to test each person’s strength, abilities, and designs.`],
            role: [],
            keys: [`conflict`, `competition`, `cooperation`, `joining forces`, `war games`, `testing skills`, `group dynamic`, `developing abilities`, `learning together`, `competing goals or motivations`, `finding one’s place in a hierarchy`],
            revkeys: [`warfare`, `doing battle`, `destruction`, `failure to learn`, `yet to be tested`, `homogenous group`, `veneer of cooperation`, `veneer of conflict`, `over estimation of skill or knowledge`, `breaking chain of command`],
            desc: [],
            revdesc: [],
            major: [`The Five of Wands is connected with The Hierophant as a five card, Strength as it is in the sign of Leo, and The World through its saturnine influence.`],
        },{
            id: 27,
            suit: `wands`,
            title: `Six of Wands`,
            subtitle: `Lord of Victory`,
            icons: [`♃`,`♌`],
            element: [`fire`],
            subelem: [],
            astro: [`Jupiter in the 2nds decan of Leo`],
            subastro: [`10-20° of Leo (August 2- 11th) Jupiter brings power and authority to the pinnacle of Leo.`],
            seph: [`Corresponding with Tiphareth, the sixth sephira is the sphere of regeneration, death and rebirth. Tiphareth infuses the Six of Wands with radiance, momentum, and energy.`],
            role: [],
            keys: [`victory`, `success`, `insight`, `wisdom`, `recognition`, `standing out as a leader`, `standing above the crowd`, `effective leadership`, `overcoming petty arguments`, `truth winning out`, `responsibility that comes with authority or power`],
            revkeys: [`struggle to succeed or stand out`, `distracted by petty squabbles`, `dishonesty`, `lacking responsibility`, `ineffective leadership`, `plans falling apart`, `success left unrecognized`],
            desc: [],
            revdesc: [],
            major: [`The Six of Wands is connected with The Lovers as a six card, Strength  as it is in the sign of Leo, and The Wheel of Fortune through the presence of Jupiter.`],
        },{
            id: 28,
            suit: `wands`,
            title: `Seven of Wands`,
            subtitle: `Lord of Valor`,
            icons: [`♂`,`♌`],
            element: [`fire`],
            subelem: [],
            astro: [`Mars in the 3rd decan of Leo`],
            subastro: [`20-30° of Leo (August 12- 22nd) The charismatic and protective qualities of Leo synergize with the assertive and unapologetic qualities of Mars.`],
            seph: [`Corresponding with Netzach, the seventh sephira is the sphere of artistic vision, and creative force. In the Seven of Wands, Netzach revels in the execution of creative vision, and proliferation of one’s ideas or descendants.`],
            role: [],
            keys: [`will`, `determination`, `rallying the troops`, `empowering allies`, `facing conflict head on`, `position of advantage`, `courage in the face of danger`, `defending one’s position`, `reaching new heights`, `finding new challenges`],
            revkeys: [`tired of fighting`, `surrender`, `giving up`, `avoiding conflict`, `fighting with allies`, `position of disadvantage`, `going on the attack`, `struggle to move on`, `facing internal enemies or demons`],
            desc: [],
            revdesc: [],
            major: [`The Seven of Wands is connected with The Chariot as a seven card, Strength as it is in the sign of Leo, and The Tower through its martian influence.`],
        },{
            id: 29,
            suit: `wands`,
            title: `Eight of Wands`,
            subtitle: `Lord of Swiftness`,
            icons: [`☿`,`♐`],
            element: [`fire`],
            subelem: [],
            astro: [`Mercury in the 1st decan of Sagittarius`],
            subastro: [`0-10° of Sagittarius (November 23- December 2nd) Mercury in detriment. Mercury, planet of early education and local travel is pushed to expand in the sign of higher education and long distance travel.`],
            seph: [`Corresponding with Hod, the eighth sephira tests, refines and fortifies the designs of Netzach. In the Eight of Wands, Hod applies scientific rigor and practicality to areas of personal growth and communication`],
            role: [],
            keys: [`swift communication`, `making connections`, `setting long term goals`, `pushing boundaries`, `expanded understanding`, `momentum`, `experience outside one’s comfort zone`, `an overwhelming amount of information`],
            revkeys: [`echo chamber`, `disrupted communication`, `lost in translation`, `shouting to be heard`, `not listening`, `avoiding the unknown`, `lack of information`, `sense of bombardment`, `delayed messages`],
            desc: [],
            revdesc: [],
            major: [`The Eight of Wands is connected with Strength as an eight card, Temperance as it is in the sign of Sagittarius, and The Magician through its mercurial influence.`],
        },{
            id: 30,
            suit: `wands`,
            title: `Nine of Wands`,
            subtitle: `Lord of Great Strength`,
            icons: [`☾`,`♐`],
            element: [`fire`],
            subelem: [],
            astro: [`Moon in the 2nd decan of Sagittarius`],
            subastro: [`10-20° degrees of Sagittarius (December 3-12th) Moon in Sagittarius brings balance of intuition and reason.`],
            seph: [`Corresponding with Yesod, the ninth sephira lays foundations and inspires dreams. In the Nine of Wands, Yesod supports the ability to reflect with clarity, and prepare for what is next.`],
            role: [],
            keys: [`drawing on past experience to inform future plans`, `determination`, `learning from past mistakes`, `retreat and regrouping`, `wounded warrior`, `strategic defense`, `labor of love`, `nearing the end of a long road`, `reflecting on recent experiences or lessons`],
            revkeys: [`failure to learn from past experience`, `lack of determination`, `pressing ahead without consideration`, `becoming bitter`, `paranoia`, `revenge`, `struggle continues`, `unable to see the end`],
            desc: [],
            revdesc: [],
            major: [`The Nine of Wands if connected with The Hermit as a nine card, Temperance as it is in the sign of Sagittarius, and The High Priestess through the presence of the moon.`],
        },{
            id: 31,
            suit: `wands`,
            title: `Ten of Wands`,
            subtitle: `Lord of Oppression`,
            icons: [`♄`,`♐`],
            element: [`fire`],
            subelem: [],
            astro: [`Saturn in the 3rd decan of Sagittarius`],
            subastro: [`20-30° degrees of Sagittarius (December 13- 21st) Saturn brings a dose of reality to freedom loving, philosophic Sagittarius.`],
            seph: [`Corresponds with Malkuth, the tenth sephira, material manifestation of creative power. In the Ten of Wands, Malkuth brings home the weight of choices made and experiences had in the physical world.`],
            role: [],
            keys: [`struggle to materialize dreams`, `work of enacting change`, `weight of untried dreams`, `burden of responsibility`, `responsibility that comes with wisdom or knowledge`, `discipline required to accomplish goals`, `completing a goal`, `learning an important lesson`, `passing a test`],
            revkeys: [`giving up on dreams`, `wishing for change without acting for change`, `taking on a daunting challenge`, `struggling to understand the lesson`, `burden of ignorance`, `lacking discipline`, `avoiding responsibility`, `choosing to give up power in order to gain freedom`],
            desc: [],
            revdesc: [],
            major: [`The Ten of Wands is connected with The Wheel of Fortune as a ten card, Temperance as it is in the sign of Sagittarius, and The World through its saturnine influence.`],
        },{
            id: 32,
            suit: `wands`,
            title: `Page of Wands`,
            subtitle: `Child of Fire`,
            icons: [`♈♌♐`],
            element: [`Element of fire`],
            subelem: [],
            astro: [`all fire signs`],
            subastro: [`Aries`, `Leo`, `Sagittarius`],
            seph: [`Corresponds with Malkuth, the tenth sephira, material manifestation of creative power. Malkuth empowers the Page of Wands to fully experience the qualities of fire.`],
            role: [`Messenger and Student`],
            keys: [`personification of fire uncomplicated by experience`, `enthusiastic`, `passionate`, `creative`, `intense desire to learn and develop (especially spiritually)`, `loves intensely`, `craves attention`, `charismatic`, `may burn low with a hot glow, or burst to life full of energy and motion`, `wields the power of the Ace of Wands`, `brings information that provides focus or orientation`],
            revkeys: [`uncontained personality`, `undisciplined`, `obsessive`, `temperamental`, `aggressive`, `arrogant`, `inconsiderate`, `tends toward extremes`, `burns out quickly`, `requires quality fuel to thrive`, `quick to anger when frustrated`, `explosive`, `may add to confusion`, `misleading information`],
            desc: [],
            revdesc: [],
            major: [`The Page of Wands is influenced by the major arcana cards ruled by the three fire signs; The Emperor (Aries), Strength (Leo), and Temperance (Sagittarius).`],
        },{
            id: 33,
            suit: `wands`,
            title: `Knight of Wands`,
            subtitle: `Lord of Sagittarius`,
            icons: [`♐♏`],
            element: [`fire`],
            subelem: [`Knights combine the element of Fire with their native element.`],
            astro: [`Sagittarius, mutable fire sign`],
            subastro: [`20° of Scorpio- 20° of Sagittarius (November 13- December 12th) Herald of Winter`],
            seph: [`Knights correspond with Tiphareth, the sixth sephira, sphere of regeneration, death and rebirth. Tiphareth empowers the Knight of Wands to develop a strong sense of self, and transform fearlessly.`],
            role: [`Hero and Quester`],
            keys: [`free thinking explorer`, `craves adventure`, `hates to be tied down`, `adaptive`, `forceful`, `destructive if left unchecked`, `passionate in his pursuits`, `potent`, `sexually motivated`, `passionate lover`, `impatient`, `flexible`, `spirituality centers him`, `driven`, `pushes boundaries`, `purifies`, `unites`, `releases bound energy`, `restores balance`, `his quest is to expand consciousness and understanding of nature`],
            revkeys: [`irrational`, `intensely emotional`, `runs wild`, `destructive`, `takes without giving`, `selfish lover`, `unfaithful`, `tyrannical`, `divisive`, `prone to rages and violence`, `insecure`, `hotheaded`, `self serving`, `dominating`, `a capable enemy`, `he supports consolidation of power and limits access to spiritual truth`],
            desc: [],
            revdesc: [],
            major: [`Temperance, assigned to the sign Sagittarius, teaches the Knight of Wands that the process of the journey is often more fulfilling than the destination.`],
        },{
            id: 34,
            suit: `wands`,
            title: `Queen of Wands`,
            subtitle: `Lady of Aries`,
            icons: [`♈`,`♓`],
            element: [`fire`],
            subelem: [`Queens combine the element of Water with their native element.`],
            astro: [`Aries, cardinal fire`],
            subastro: [`20° of Pisces- 20° of Aries (March 11- April 10th), Initiates Spring.`],
            seph: [`Corresponding with Binah, the third sephira gives form to concepts and begins to shape plans. Binah empowers the Queen of Wands to actively bring her plans to fruition.`],
            role: [`Nurturer and Guardian`],
            keys: [`romantic`, `passionate`, `unrestrained lover`, `creative`, `determined`, `strong`, `willful`, `brave`, `impulsive`, `a strong leader`, `loyal`, `protective`, `competitive`, `aggressive`, `energetic`, `curious`, `fast moving`, `destructive if unchecked`, `direct in her approach`, `mystical`, `holds power of transformation`, `she protects and nurtures personal power and autonom`, `supporting growth through curiosity and experience`],
            revkeys: [`uses loving expression or sex as a means of control`, `domineering`, `ineffective`, `infertile`, `creative blocks`, `cut off from her power`, `vengeful`, `bullying`, `suggestable`, `unquenchable`, `acts before considering consequences`, `a determined enemy`, `imperialistic`, `she seeks to amass and reinforce her own power by conquering others`],
            desc: [],
            revdesc: [],
            major: [`The Queen of Wands is connected with both The Emperor and The Empress. As a queen she represents a mortal incarnation of The Empress, and in the sign of Aries she is in union with The Emperor. As the initiator of Spring this queen symbolizes the joy of creativity, creative partnership, and sexuality.`],
        },{
            id: 35,
            suit: `wands`,
            title: `King of Wands`,
            subtitle: `Lord of Leo`,
            icons: [`♌`,`♋`],
            element: [`fire`],
            subelem: [`Kings combine the element of Air with their native element.`],
            astro: [`Leo, fixed fire`],
            subastro: [`20° of Cancer- 20° of Leo (July 12- August 11th) Summer in its power.`],
            seph: [`Corresponding with Chokmah, the second sephira explores duality and choices. Chokmah empowers the King of Wands to consider multiple angles, and make choices judiciously.`],
            role: [`Keeper and Administrator`],
            keys: [`creative`, `confident`, `courageous`, `charismatic`, `forceful`, `explosive`, `wise`, `self aware`, `directed`, `may be harsh`, `determined`, `authoritative`, `natural leader`, `dramatic`, `inspirational`, `loyal`, `family oriented`, `devoted and passionate lover`, `protective`, `he sets limits intended to encourage healthy growth, inspiring creative drive and problem solving`],
            revkeys: [`explosive temper`, `suffocating`, `narcissistic`, `vain`, `hypnotic`, `manipulative`, `potential cult leader`, `ungrounded`, `violent`, `unpredictable`, `authoritarian`, `effectively plays on fears`, `unfaithful`, `jealous`, `easily threatened`, `vengeful`, `a dangerous enemy`, `he demands complete control, and is obsessed with reinforcing his own power regardless of impact to others`],
            desc: [],
            revdesc: [],
            major: [`Strength`],
        },{
            id: 36,
            suit: `cups`,
            title: `Ace of Cups`,
            subtitle: `Power of Water`,
            icons: [`♋♏♓`],
            element: [`water`],
            subelem: [],
            astro: [`all water signs`],
            subastro: [`Cancer`, `Scorpio`, `Pisces`],
            seph: [`Corresponding with Kether, the first sephira is the source of consciousness and beginnings. In the Ace of Cups, consciousness merges with a material body creating an opportunity to explore feelings and relationships.`],
            role: [],
            keys: [`personal and collective subconscious`, `spring of emotions`, `deep feelings`, `intuition`, `love`, `compassion`, `source of relationships`, `start of a deep or intense relationship`, `reuniting`, `making connections`, `spirit`, `becoming incarnate`, `receptive`, `feminine`],
            revkeys: [`illusion of separateness`, `isolation`, `unchecked emotional response`, `flood of feelings`, `swept up in a relationship`, `surface level feelings`, `denial of spiritual or emotional experience`, `conflict`, `breaking apart`, `passing away`],
            desc: [],
            revdesc: [],
            major: [`The Ace of Cups is connected to The Magician as a one card, and each of the major arcana cards ruled by a water sign; The Chariot (Cancer), Death (Scorpio), and The Moon (Pieces)`],
        },{
            id: 37,
            suit: `cups`,
            title: `Two of Cups`,
            subtitle: `Lord of Love`,
            icons: [`♀`,`♋`],
            element: [`water`],
            subelem: [],
            astro: [`Venus in the 1st decan of Cancer`],
            subastro: [`0-10° of Cancer (June 21- July 1st) Venus brings her power of love and attraction to the sign of home & family.`],
            seph: [`Corresponding with Chokmah, the second sephira explores duality and choices. In the Two of Cups, Chokmah invites us to explore through conversation and partnership.`],
            role: [],
            keys: [`marriage`, `partnership`, `innocent love`, `one on one connection`, `co-creation`, `shared experiences`, `starting something together`, `balance`, `harmony`, `union`, `mutual respect`, `being on even ground`, `matching feelings`, `seeing another with love`, `seeing clearly`, `compatibility`, `potential for development`],
            revkeys: [`incompatibility`, `uneven partnership`, `power imbalance`, `blinded by love`, `uneven contribution`, `mismatched feelings`, `superficial connection`, `fleeting romance`, `temporary partnership`],
            desc: [],
            revdesc: [],
            major: [`The Two of Cups is connected with the High Priestess as a two card, The Chariot as it is in the sign of Cancer, and The Empress through its venusian influence.`],
        },{
            id: 38,
            suit: `cups`,
            title: `Three of Cups`,
            subtitle: `Lord of Abundance`,
            icons: [`☿`,`♋`],
            element: [`water`],
            subelem: [],
            astro: [`Mercury in the 2nd decan of Cancer`],
            subastro: [`10-20° of Cancer (July 2- 11th) planet of commerce and exchange in a sign of community.`],
            seph: [`Corresponding with Binah, the third sephira gives form to concepts and begins to shape plans. In the Three of Cups, Binah shows the potential of collective effort and sharing.`],
            role: [],
            keys: [`time of celebration`, `abundance`, `sharing equitably`, `joining together`, `joyful interaction`, `friendship`, `family ties`, `group dynamics`, `cooperation`, `good harvest`, `community building`, `strengthening bonds`, `participation`],
            revkeys: [`grieving together`, `a wake`, `scarcity`, `jealousy`, `love triangle`, `fractured family or community`, `warring factions`, `conflict`, `isolation`, `expulsion from a group or community`, `undermining bonds`, `non-participation`],
            desc: [],
            revdesc: [],
            major: [`The Three of Cups is connected with The Empress as a three card, The Chariot as it is in the sign of Cancer, and The Magician through its mercurial influence.`],
        },{
            id: 39,
            suit: `cups`,
            title: `Four of Cups`,
            subtitle: `Lord of Blended Pleasure`,
            icons: [`☾`,`♋`],
            element: [`water`],
            subelem: [],
            astro: [`Moon in the 3rd decan of Cancer`],
            subastro: [`20-30° of Cancer (July 12- 21st) The Moon in dignity. At home the reflective, empathic Moon tends to her own needs.`],
            seph: [`Corresponding with Chesed, the fourth sephira preserves and protects what has been established. In the Four of Cups, Chesed emphasizes the importance of restoration, and wise use of emotional and social resources.`],
            role: [],
            keys: [`rest and recuperation`, `choosing opportunities wisely`, `self care`, `reflecting on feelings or relationships`, `disillusionment`, `disappointment`, `taking personal time`, `maintaining healthy boundaries in relationships of all kinds`],
            revkeys: [`overbooked`, `burning the candle at both ends`, `need for rest and reflection`, `temporary disengagement`, `pressure to socialize or connect`, `fear of missing out`, `failure to recognize or appreciate an opportunity`],
            desc: [],
            revdesc: [],
            major: [`The Four of Cups is connected to the Emperor as a four card, The Chariot as it is in the sign of Cancer, and The High Priestess through its lunar influence.`],
        },{
            id: 40,
            suit: `cups`,
            title: `Five of Cups`,
            subtitle: `Lord of Loss of Pleasure`,
            icons: [`♂`,`♏`],
            element: [`water`],
            subelem: [],
            astro: [`Mars in the 1st decan of Scorpio`],
            subastro: [`0-10° degrees of Scorpio (October 23- November 2nd) Accepting death and loss as part of Life.`],
            seph: [`Corresponding with Geburah, the fifth sephira puts all that is to the test, and dissolves what no longer serves.`],
            role: [],
            keys: [`learning to accept loss`, `finding courage to carry on`, `change in circumstance`, `gifts waiting to be discovered`, `time of transition`, `processing grief`, `regret`],
            revkeys: [`dwelling on what is lost`, `depression`, `struggling to carry on`, `loss of hope`, `failure to see gifts`, `fixation on what has been lost`, `loss overshadowing gains`],
            desc: [],
            revdesc: [],
            major: [`Death`, `The Tower`, `The Hierophant`],
        },{
            id: 41,
            suit: `cups`,
            title: `Six of Cups`,
            subtitle: `Lord of Pleasure`,
            icons: [`☉`,`♏`],
            element: [`water`],
            subelem: [],
            astro: [`Sun in the 2nd decan of Scorpio`],
            subastro: [`10-20° of Scorpio (November 3- 12th) Sun brings light to our deepest darkest places.`],
            seph: [`Corresponding with Tiphareth, the sixth sephira is the sphere of regeneration, death and rebirth. In the Six of Cups, Tiphareth reminds us that everything returns in its season.`],
            role: [],
            keys: [`harmony`, `friendship`, `reunion`, `giving freely without strings attached`, `childhood influences`, `non-romantic love`, `showing kindness`, `generosity of spirit`, `giving aid`, `nurturing growth`],
            revkeys: [`score keeping`, `giving attached to expectation`, `rivalry`, `withholding`, `frenemies`, `betrayal`, `abandonment`, `friendship blossoming into romance`, `seeing someone in a new light`],
            desc: [],
            revdesc: [],
            major: [`Death`, `The Sun`, `The Lovers`],
        },{
            id: 42,
            suit: `cups`,
            title: `Seven of Cups`,
            subtitle: `Lord of Illusionary Success`,
            icons: [`♀`,`♏`],
            element: [`water`],
            subelem: [],
            astro: [`Venus in the 3rd decan of Scorpio`],
            subastro: [`20-30° of Scorpio (November 13- 22nd) Venus brings visions of success and achievement yet unsubstantiated.`],
            seph: [`Corresponding with Netzach, the seventh sephira is the sphere of artistic vision, and creative force. In the Seven of Cups, Netzach uses the power of the imagination to create detailed visions without the investment necessary for creation in physical reality.`],
            role: [],
            keys: [`fantasy`, `dreams`, `glamor`, `false promises`, `seeking external validation through status symbols or fleeting experiences`, `ideas yet to be put into action`, `need to make a choice with limited information`, `everything is not what it seems`, `feeling overwhelmed`],
            revkeys: [`escapism`, `lost in fantasy`, `deception`, `abundance of ideas but lack of follow through`, `missed opportunities`, `disillusionment`, `narrow options`, `feeding vanity`, `visions of grandeur`, `paralyzed by indecision`],
            desc: [],
            revdesc: [],
            major: [`Death`, `The Empress`, `The Chariot`],
        },{
            id: 43,
            suit: `cups`,
            title: `Eight of Cups`,
            subtitle: `Lord of Abandoned Success`,
            icons: [`♄`,`♓`],
            element: [`water`],
            subelem: [],
            astro: [`Saturn in the 1st decan of Pisces`],
            subastro: [`0-10° of Pisces (February 19- 28th) Saturn, planet of boundaries, will end whatever does not stand up to scrutiny in the sign most apt to changing course.`],
            seph: [`Corresponding with Hod, the eighth sephira tests, refines and fortifies the designs of Netzach. In the Eight of Cups, Hod supports the quest for meaningful satisfaction, and a full realization of the heart’s desires.`],
            role: [],
            keys: [`leaving something good in favor of a higher calling`, `abandoning an old dream`, `in search of satisfaction and meaning`, `confidence to take risks`, `exploration`, `integrity`, `healthy growth or transition`, `everything has a cost`, `taking responsibility for one’s own happiness`],
            revkeys: [`lacking commitment`, `avoiding responsibility`, `forgoing pleasure due to the cost (or perceived cost)`, `distrust of blessings`, `running from problems`, `restlessness`, `in need of a change`, `holding on to something beyond its time or usefulness`],
            desc: [],
            revdesc: [],
            major: [`The Moon`, `The World`, `Strength`],
        },{
            id: 44,
            suit: `cups`,
            title: `Nine of Cups`,
            subtitle: `Lord of Material Happiness`,
            icons: [`♃`,`♓`],
            element: [`water`],
            subelem: [],
            astro: [`Jupiter in the 2nd decan of Pisces`],
            subastro: [`10-20° of Pisces (March 1-10th) Jupiter at in dignity. Jupiter brings prosperity and abundance to compassionate and imaginative Pisces.`],
            seph: [`Corresponding with Yesod, the ninth sephira lays foundations and inspires dreams. In the Nine of Cups, Yesod offers the emotional and social resources to interact with confidence, and dream big.`],
            role: [],
            keys: [`comfort`, `joy`, `having plenty to share and friends to share with`, `living in abundance`, `needs are met`, `no fear of lack`, `generous nature`, `strong sense of community`, `self assured`, `attractive`, `magnetic`, `charismatic`, `confident`],
            revkeys: [`insecure`, `ill at ease`, `fear of lack`, `greed`, `emotional neediness`, `miserly`, `transactional`, `needs external validation`, `awkwardness`, `loneliness`, `lacks social awareness or graces`, `hidden treasure`, `diamond in the rough`],
            desc: [],
            revdesc: [],
            major: [`The Moon`, `The Wheel of Fortune`, `The Hermit`],
        },{
            id: 45,
            suit: `cups`,
            title: `Ten of Cups`,
            subtitle: `Lord of Perfected Success`,
            icons: [`♂`,`♓`],
            element: [`water`],
            subelem: [],
            astro: [`Mars in the 3rd decan of Pisces`],
            subastro: [`20-30° of Pisces (March 11- 20th) The “Go get ‘em” attitude of Mars applied to personal happiness and home life.`],
            seph: [`Corresponding with Malkuth, the tenth sephira is material manifestation of creative power, and the world we all inhabit. In the Ten of Cups, Malkuth supports efforts to bring our deepest desires into tangible reality.`],
            role: [],
            keys: [`meaningful happiness`, `success in relationships`, `an idyllic home`, `harmonious family`, `wholeness`, `balanced and cooperative energies`, `joyful marriage`, `starting a family`, `making dreams real`, `meaningful interpersonal connections`, `empowerment`, `something worth defending`, `open pursuit of deepest desires`],
            revkeys: [`superficial happiness`, `fleeting romantic connection`, `temporary peace or refuge`, `disruption of domestic tranquility`, `feeling trapped by responsibility`, `escapist fantasy`, `conflict at home or in primary relationships`, `challenges of parenthood`],
            desc: [],
            revdesc: [],
            major: [`The Moon`, `The Tower`, `The Wheel of Fortune`],
        },{
            id: 46,
            suit: `cups`,
            title: `Page of Cups`,
            subtitle: `Spirit of Water`,
            icons: [`♋♏♓`],
            element: [`water`],
            subelem: [],
            astro: [`all water signs`],
            subastro: [`Cancer`, `Scorpio`, `Pisces`],
            seph: [`Corresponding with Malkuth, the tenth sephira is material manifestation of creative power, and the world we all inhabit. Malkuth empowers the Page of Cups to fully experience the qualities of water.`],
            role: [`Messenger and Student`],
            keys: [`personification of water uncomplicated by experience`, `adaptable`, `forceful`, `calm or temperamental as the sea`, `connects what seems separate`, `transitions easily`, `empathetic`, `intuitive`, `enthusiastic`, `sensitive`, `generally optimistic`, `a muse`, `receiving inspiration`, `keeper of the Ace of Cups`, `loves to deliver personal messages`, `especially love letters`],
            revkeys: [`prone to tantrums`, `storms may come and go quickly`, `manipulative`, `prefers surface level interactions`, `secretive`, `struggling to assert independence`, `impressionable`, `reactionary`, `yet unaware of personal power`, `learning to control power effectively`, `may bring sad news, or act as a harbinger of heartbreak`],
            desc: [],
            revdesc: [],
            major: [`The Chariot`, `Death`, `The Moon`],
        },{
            id: 47,
            suit: `cups`,
            title: `Knight of Cups`,
            subtitle: `Lord of Pisces`,
            icons: [`♓`,`♒`],
            element: [`water`],
            subelem: [`Knights combine the element of Fire with their native element.`],
            astro: [`Pisces, mutable water`],
            subastro: [`20° of Aquarius- 20° of Pisces (February 9- March 10th) Herald of Spring`],
            seph: [`Corresponding with Tiphareth, the sixth sephira is the sphere of regeneration, death and rebirth. Tiphareth empowers the Knight of Cups to follow his heart and love fully, free from the fear of painful loss.`],
            role: [`Hero and Quester`],
            keys: [`diplomatic`, `romantic`, `sensitive`, `passionate`, `driven`, `forceful or peaceful depending on the need`, `empathic`, `offers friendship and support`, `intuitive`, `dreamer`, `charming`, `chivalrous`, `adventurous`, `his quest is to unite people and nurture common dreams`],
            revkeys: [`manipulative`, `destructive`, `dark empath`, `unreliable`, `temperamental`, `veneer of friendship`, `dreams unfulfilled`, `personal feelings are paramount`, `loss of an ally`, `keeps others at a distance`, `untrusting`, `suspicious`, `gives too much`, `he sows discord and turns dreams to nightmares`],
            desc: [],
            revdesc: [],
            major: [`The Moon`],
        },{
            id: 48,
            suit: `cups`,
            title: `Queen of Cups`,
            subtitle: `Lady of Cancer`,
            icons: [`♋`,`♊`],
            element: [`water`],
            subelem: [`Queens combine the element of Water with their native element.`],
            astro: [`Cancer, cardinal water`],
            subastro: [`20° of Gemini- 20° of Cancer (June 11- July 11th) Initiates Summer`],
            seph: [`Corresponding with Binah, the third sephira gives form to concepts and begins to shape plans. Binah empowers the Queen of Cups to receive others as they are while keeping her own boundaries in a firm but gracious manner.`],
            role: [`Nurturer and Guardian`],
            keys: [`creative`, `artistic`, `sensitive`, `navigates emotional situations with ease`, `loving`, `caregiver`, `fluid but healthy boundaries`, `may be gentle or overpowering`, `keeps secrets`, `mysterious`, `free flowing`, `empathetic`, `perceptive`, `makes connections easily`, `protective of those she loves`, `capable of shaping the world`, `adapts to circumstances`, `she protects and nurtures the relationships within her community, promoting respect and trust`],
            revkeys: [`stagnates when hemmed in`, `struggles with healthy boundaries`, `shares inappropriately`, `overpowering`, `forceful`, `may become cold or rigid`, `tempestuous`, `manipulative`, `creative blocks`, `insensitive to the feelings of others`, `xenophobic`, `overprotective`, `relentless`, `suggestable`, `takes on the shape of her surroundings`, `she manipulates relationships for her own ends and sabotages anything she deems a social threat`],
            desc: [],
            revdesc: [],
            major: [`The Chariot`],
        },{
            id: 49,
            suit: `cups`,
            title: `King of Cups`,
            subtitle: `Lord of Scorpio`,
            icons: [`♏`,`♎`],
            element: [`water`],
            subelem: [`Kings combine the element of Air with their native element.`],
            astro: [`Scorpio, fixed water`],
            subastro: [`20° of Libra- 20° of Scorpio (October 13- November 12th) Fall in its power`],
            seph: [`Corresponding with Chokmah, the second sephira explores duality and choices. Chokmah empowers the King of Cups to understand the driving forces within his realm, and effectively lead by speaking directly to the hearts of his people.`],
            role: [`Keeper and Administrator`],
            keys: [`deep`, `mysterious`, `tuned in with the currents of his realm but not swept up in them`, `intuitive`, `intellectual`, `craves connection`, `may be prone to moodiness`, `mild and welcoming when calm`, `terrifying and destructive when wrathful`, `perceptive`, `experienced`, `he uses his emotional intelligence to promote the prosperity and sense of connection within his realm, uniting his people and humanizing his enemies`],
            revkeys: [`shallow`, `superficial`, `obvious`, `easily swayed`, `prone to being emotionally reactive`, `manipulative`, `dark empath`, `tempestuous`, `unreliable`, `hides his deeper nature`, `untrusting`, `untrustworthy`, `fears isolation`, `struggles with change`, `he plays on the fears of others to promote personal gain`, `sowing discord and distrust as a means of reinforcing or increasing his own influence`],
            desc: [],
            revdesc: [],
            major: [`Death`],
        },{
            id: 50,
            suit: `swords`,
            title: `Ace of Swords`,
            subtitle: `Power of Air`,
            icons: [`♎♒♊`],
            element: [`air`],
            subelem: [],
            astro: [`all air signs`],
            subastro: [`Libra`, `Aquarius`, `Gemini`],
            seph: [`Corresponding with Kether, the first sephira is the source of consciousness and beginnings. In the Ace of Swords, Kether offers new ideas, new insights, and the responsibility for how ideas and insights are put to use.`],
            role: [],
            keys: [`self awareness`, `intellect`, `reason`, `adaptability`, `balance between mercy and severity`, `use of tools`, `problem solving`, `duality`, `unity of opposites`, `utility`, `potential to protect or destroy`, `idealism`, `structured creativity`, `authority`, `victory`, `projective`, `masculine`],
            revkeys: [`cognitive dissonance`, `rationalizing incorrect behavior or thinking`, `irrationality`, `struggling to find solutions`, `intellectual frustration or boredom`, `illusion of separateness`, `defeat`, `imbalance between mercy and severity`],
            desc: [],
            revdesc: [],
            major: [`Justice`, `The Star`, `The Lovers`],
        },{
            id: 51,
            suit: `swords`,
            title: `Two of Swords`,
            subtitle: `Lord of Peace Restored`,
            icons: [`☾`,`♎`],
            element: [`air`],
            subelem: [],
            astro: [`Moon in the 1st decan of Libra`],
            subastro: [`0-10 degrees of Libra (September 23- October 2nd) The Moon graces intellectual Libra with reflection, acceptance, and understanding.`],
            seph: [`Corresponding with Chokmah, the second sephira explores duality and choices. In the Two of Swords, Chokmah supports the courage to keep going even when we cannot know the outcome of our choices.`],
            role: [],
            keys: [`a difficult choice`, `facing a crossroads`, `lack of solid information`, `finding balance`, `learning to trust one’s intuition`, `expanding understanding`, `quieting the mind`, `looking for answers within`],
            revkeys: [`indecision`, `confusion`, `misinformation`, `crossed messages`, `an agitated mind`, `mistrust`, `ignoring a problem`, `kept in the dark`],
            desc: [],
            revdesc: [],
            major: [`Justice`, `The High Priestess`],
        },{
            id: 52,
            suit: `swords`,
            title: `Three of Swords`,
            subtitle: `Lord of Sorrow`,
            icons: [`♄`,`♎`],
            element: [`air`],
            subelem: [],
            astro: [`Saturn in the 2nd decan of Libra`],
            subastro: [`10-20° of Libra (October 3- 12th) Saturn in exaltation. Imposing limitations to preserve balance.`],
            seph: [`Corresponding with Binah, the third sephira gives form to concepts and begins to shape plans. In the Three of Swords, Binah reminds us that sacrifice is a necessary part of balance.`],
            role: [],
            keys: [`heartbreak`, `everything comes at a cost`, `painful compromise`, `seemingly irreconcilable differences`, `competing motivations or goals`, `infidelity`, `loss`, `perceived injustice`, `uncomfortable truth`, `divided loyalty`, `acute pain or grief`],
            revkeys: [`true cost is hidden`, `refusal to compromise`, `clinging to something that is not serving`, `gnawing disquiet`, `grief or pain recedes to to background`, `chronic pain or grief`],
            desc: [],
            revdesc: [],
            major: [`Justice`, `The World`, `The Empress`],
        },{
            id: 53,
            suit: `swords`,
            title: `Four of Swords`,
            subtitle: `Lord of Rest from Strife`,
            icons: [`♃`,`♎`],
            element: [`air`],
            subelem: [],
            astro: [`Jupiter in the 3rd decan of Libra`],
            subastro: [`20-30° of Libra (October 13- 22nd) The great benefic blesses busy Libra with rest and rejuvenation.`],
            seph: [`Corresponding with Chesed, the fourth sephira preserves and protects what has been established. In the Four of Swords, Chesed allows the mind a chance to rest and rejuvenate.`],
            role: [],
            keys: [`taking time for rest and recovery`, `collecting one’s thoughts`, `being at peace`, `calming the mind`, `temporary reprieve`, `time out from doing and socializing`, `taking the time to dream`],
            revkeys: [`over work`, `exhaustion`, `an active or racing mind`, `frenetic energy`, `restlessness`, `speaking before thinking`, `need for down time`, `sense of isolation`, `intellectual quagmire or paradox`],
            desc: [],
            revdesc: [],
            major: [`Justice`, `The Wheel of Fortune`, `The Emperor`],
            },           
            {
            id: 54,
            suit: `swords`,
            title: `Five of Swords`,
            subtitle: `Lord of Defeat`,
            icons: [`♀`,`♒`],
            element: [`air`],
            subelem: [],
            astro: [`Venus in the 1st decan of Aquarius`],
            subastro: [`0-10° of Aquarius (January 20- 29th) Venus strives for harmony amongst the people.`],
            seph: [`Corresponding with Geburah, the fifth sephira puts all that is to the test, and dissolves what no longer serves. In the Five of Swords, Geburah challenges us to look at how we are spending our effort, and consider whether it is in service of our true desires.`],
            role: [],
            keys: [`winning the battle but losing the war`, `poor sportsmanship`, `dehumanizing perceived enemies`, `aftermath of battle`, `bittersweet outcome`, `walking away from a fight`, `choices leading to isolation`, `driving away potential allies`, `having an intellectual advantage`, `sense of intellectual superiority`, `reason winning out`],
            revkeys: [`attacking allies as enemies`, `dominating the conversation`, `sabotaging discourse`, `poor strategy`, `winning at all costs`, `saving facing over finding truth`, `ideas communicated poorly`, `overestimating knowledge or understanding of an issue`],
            desc: [],
            revdesc: [],
            major: [`The Star`, `The Empress`, `The Hierophant`],
        },{
            id: 55,
            suit: `swords`,
            title: `Six of Swords`,
            subtitle: `Lord of Earned Success`,
            icons: [`☿`,`♒`],
            element: [`air`],
            subelem: [],
            astro: [`Mercury in the 2nd decan of Aquarius`],
            subastro: [`10-20° of Aquarius (January 30- February 8th) Mercury as guide and ferryman between worlds.`],
            seph: [`Corresponding with Tiphareth, the sixth sephira is the sphere of regeneration, death and rebirth. In the Six of Swords, Tiphareth prepare us for transformation or a growth spurt.`],
            role: [],
            keys: [`a short but profound journey`, `receiving or giving support and guidance`, `seeking profound change`, `questioning the status quo`, `bridging gaps`, `forging connections`, `intuitive leaps`],
            revkeys: [`unable to see progress`, `accepting the status quo without question`, `unable to make intuitive leaps`, `lacking support or guidance`, `inability to give support at this time`, `ridgid way of thinking`],
            desc: [],
            revdesc: [],
            major: [`The Star`, `The Magician`, `The Lovers`],
        },{
            id: 56,
            suit: `swords`,
            title: `Seven of Swords`,
            subtitle: `Lord of Unstable Effort`,
            icons: [`☾`,`♒`],
            element: [`air`],
            subelem: [],
            astro: [`Moon in 3rd decan of Aquarius`],
            subastro: [`20-30° of Aquarius (February 9- 18th) The shadowy aspect of the Moon stirring up doubt and fear in the sign of social groups and causes.`],
            seph: [`Corresponding with Netzach, the seventh sephira is the sphere of artistic vision, and creative force. In the Seven of Swords, Netzach encourages creative problem solving, and thinking outside the box.`],
            role: [],
            keys: [`spycraft`, `stirring up trouble`, `testing loyalties`, `taking back one’s own by unsanctioned means`, `facing fears`, `vengeance`, `mischief making`, `fear of reprisal`, `trickster antics`, `theft`],
            revkeys: [`testing defenses`, `playing mind games`, `gaslighting`, `missed opportunity`, `leaving evidence behind`, `failure to cover tracks`, `intentionally leaving a trail or clue`, `testing reactions`, `provoking a response`],
            desc: [],
            revdesc: [],
            major: [`The Star`, `The High Priestess`, `The Chariot`],
        },{
            id: 57,
            suit: `swords`,
            title: `Eight of Swords`,
            subtitle: `Lord of Shortened Force`,
            icons: [`♃`,`♊`],
            element: [`air`],
            subelem: [],
            astro: [`Jupiter in the 1st decan Gemini`],
            subastro: [`0-10° of Gemini (May 21- 31st) Jupiter in detriment. Ever expanding possibilities in this sign of thought and duality leads to scattered energy.`],
            seph: [`Corresponding with Hod, the eighth sephira tests, refines and fortifies the designs of Netzach. In the Eight of Swords, Hod pushes us to focus our attention and think strategically.`],
            role: [],
            keys: [`indecision`, `an overabundance of options`, `scattered focus`, `lost in thought`, `a trap of one’s own making`, `turning inward`, `echo chamber`, `struggling to progress`, `blindness to potential solutions`, `rejecting solutions or options`, `endless internal debate`],
            revkeys: [`feeling hemmed in`, `limited options`, `picking apart potential solutions`, `seeking the root of a problem or conflict`, `stability at a cost`, `falling victim to one’s own schemes`, `disinformation causing confusion`, `compounding errors`],
            desc: [],
            revdesc: [],
            major: [`The Lovers`, `The Wheel of Fortune`, `Strength`],
        },{
            id: 58,
            suit: `swords`,
            title: `Nine of Swords`,
            subtitle: `Lord of Despair and Cruelty`,
            icons: [`♂`,`♊`],
            element: [`air`],
            subelem: [],
            astro: [`Mars in the 2nd decan of Gemini`],
            subastro: [`10-20° of Gemini (June 1- 10th) Mars, god of war, playing mind games.`],
            seph: [`Corresponding with Yesod, the ninth sephira lays foundations and inspires dreams. In the Nine of Swords, Yesod amplifies our most ingrained thoughts.`],
            role: [],
            keys: [`anxiety`, `doubt`, `harsh words`, `negative self talk`, `playing on fears`, `nightmares`, `perverse pleasure`, `lies and shadows`, `insomnia`, `gaslighting`, `betraying personal code of ethics`, `sowing seeds of doubt or discord`, `guilt`],
            revkeys: [`releasing anxiety`, `temporary discomfort`, `process of healing`, `anxiety about a future event or future in general`, `trust your gut`, `forgiveness`, `maintaining integrity under pressure`, `dispelling doubt`],
            desc: [],
            revdesc: [],
            major: [`The Lovers`, `The Tower`, `The Hermit`],
        },{
            id: 59,
            suit: `swords`,
            title: `Ten of Swords`,
            subtitle: `Lord of Ruin`,
            icons: [`☉`,`♊`],
            element: [`air`],
            subelem: [],
            astro: [`Sun in the 3rd decan of Gemini`],
            subastro: [`20-30° of Gemini (June 11- 20th) Sun brings unrelenting truth, laying bare false constructs. `],
            seph: [`Corresponding with Malkuth, the tenth sephira is material manifestation of creative power, and the world we all inhabit. Malkuth lends the Ten of Swords the gravity it needs to enact meaningful change.`],
            role: [],
            keys: [`overkill`, `endings and beginnings`, `painful but liberating realizations`, `backstabbing`, `gossip`, `release through acceptance`, `new light dawning`, `seeming poor fortune turning to good`, `assailed by facts`, `unrelenting reality`],
            revkeys: [`defeating enemies`, `shedding light`, `turning to higher authority`, `making an ironclad case`, `keeping silent`, `resistance increasing trauma`, `real world impact of philosophy or ideology`, `necessary ending`],
            desc: [],
            revdesc: [],
            major: [`The Lovers`, `The Sun`, `The Wheel of Fortune`],
        },{
            id: 60,
            suit: `swords`,
            title: `Page of Swords`,
            subtitle: `Spirit of Air`,
            icons: [`♎♒♊`],
            element: [`air`],
            subelem: [],
            astro: [`all air signs`],
            subastro: [`Libra`, `Aquarius`, `Gemini`],
            seph: [`Corresponding with Malkuth, the tenth sephira is material manifestation of creative power, and the world we all inhabit.`],
            role: [`Messenger and Student`],
            keys: [`personification of air uncomplicated by experience`, `versatile`, `inquisitive`, `thoughtful`, `keen witted`, `clear minded`, `direct`, `insightful`, `long sighted`, `rational`, `communicative`, `idealistic`, `brash`, `wielder of the Ace of Swords`, `may bring sudden inspiration or a solution to a problem`],
            revkeys: [`two faced`, `inconsistent`, `nosy`, `gossips`, `easily distracted`, `short sighted`, `mischievous`, `struggles to complete tasks`, `easily bored`, `destructive when bored`, `prattles`, `may deliver additional problems rather than solutions`],
            desc: [],
            revdesc: [],
            major: [`Justice`, `The Star`, `The Lovers`],
        },{
            id: 61,
            suit: `swords`,
            title: `Knight of Swords`,
            subtitle: `Lord of Gemini`,
            icons: [`♊`,`♉`],
            element: [`air`],
            subelem: [`Knights combine the element of Fire with their native element.`],
            astro: [`Gemini, mutable air`],
            subastro: [`20° of Taurus- 20° of Gemini (May 11- June 10th) Herald of Summer`],
            seph: [`Corresponding with Tiphareth, the sixth sephira is the sphere of regeneration, death and rebirth.`],
            role: [`Hero and Quester`],
            keys: [`brave`, `war like`, `chivalrous`, `polished`, `impetuous`, `articulate`, `adventurous`, `curious`, `experience seeking`, `adaptable`, `self righteous`, `unbounded`, `swift`, `strategic`, `physically and mentally agile`, `jack of many trades`, `has varied interests`, `constantly in motion`, `has high aspirations`, `his quest is to unite hearts and minds`],
            revkeys: [`cowardly`, `manipulative`, `fickle`, `rude`, `studders or struggles to find words`, `poor planner`, `rigid`, `wrathful`, `aggressive`, `arrogant`, `unscrupulous`, `flouts boundaries`, `threatened by competition`, `likes to specialize`, `fatalistic`, `he sows seeds of doubt and distrust`],
            desc: [],
            revdesc: [],
            major: [`The Lovers`],
        },{
            id: 62,
            suit: `swords`,
            title: `Queen of Swords`,
            subtitle: `Lady of Libra`,
            icons: [`♎`,`♍`],
            element: [`air`],
            subelem: [`Queens combine the element of Water with their native element.`],
            astro: [`Libra, cardinal air`],
            subastro: [`20° of Virgo- 20° of Libra (September 12- October 12th) Initiates Fall`],
            seph: [`Binah`],
            role: [`Nurturer and Guardian`],
            keys: [`mediator`, `sound judge`, `both intellectual and empathic`, `sharp witted`, `understands subtext`, `clear sighted`, `decisive`, `unites head and heart`, `firm but just`, `understands loss`, `the only queen who is not a biological mother, instead adopting strays or channeling creative powers through her intellect`, `likely to be divorced or single`, `she protects and nurtures balance within her community and promotes concepts of justice`],
            revkeys: [`tends to her own advantage`, `suspicious`, `untrusting`, `calculating`, `prone to lashing out`, `narrow minded`, `biased`, `fears loss of control`, `struggles with vulnerability`, `defensive`, `tends toward extremism`, `hyperrational`, `uncomfortable dealing with emotional matters`, `wounded by loss of a child or remaining childless`, `she will manipulate concepts of justice for her own ends, or reinforce unjust constructs`],
            desc: [],
            revdesc: [],
            major: [`Justice`],
        },{
            id: 63,
            suit: `swords`,
            title: `King of Swords`,
            subtitle: `Lord of Aquarius`,
            icons: [`♒`,`♑`],
            element: [`air`],
            subelem: [`Kings combine the element of Air with their native element.`],
            astro: [`Aquarius, the fixed air sign`],
            subastro: [`20° Capricorn- 20° Aquarius (January 10- February 8th) Winter in its power`],
            seph: [`Chokmah`],
            role: [`Keeper and Administrator`],
            keys: [`rational`, `intellectual`, `decisive`, `adaptable`, `far sighted`, `clear minded`, `lofty`, `cool`, `aggressive`, `rules as he sees fit`, `changes what no longer serves`, `lacks sentimentality`, `shares knowledge`, `encourages critical thinking`, `innovative`, `may be harsh or unkind`, `humanitarian ideals motivate his actions`, `he continually strives to unlock the intellectual and creative capacity of his realm`, `promotes critical thinking skills`],
            revkeys: [`cold and calculating`, `cruel`, `manipulates idealism for personal gain`, `loves power`, `craves admiration`, `suffers from self doubt`, `misdiagnosis problems`, `discourages questions`, `controls access to information`, `fears competition`, `ruthless`, `over reliance on advisors`, `engages in charity and morality for show`, `he fears any intellect that may surpass his own`, `when threatened may engage in bad faith discourse or fallacious arguments in order to distract from the core issue`],
            desc: [],
            revdesc: [],
            major: [`The Star`],
        },{
            id: 64,
            suit: `pentacles`,
            title: `Ace of Pentacles`,
            subtitle: `Power of Earth`,
            icons: [`♑♉♍`],
            element: [`earth`],
            subelem: [],
            astro: [`all earth signs`],
            subastro: [`Capricorn`, `Taurus`, `Virgo`],
            seph: [`Corresponding with Kether, the first sephira is the source of consciousness and beginnings.`],
            role: [],
            keys: [`seed of life`, `creative potential`, `seed of possibility`, `potential growth`, `fertility`, `treasure`, `a perfect moment`, `unity`, `independent pursuit`, `personal mission`, `beauty`, `comfort`, `a safe space`, `ideal home`, `start of a journey`, `receptive`, `feminine`],
            revkeys: [`creative frustration`, `struggle to grow`, `infertility`, `loss of treasure`, `disrupted harmony`, `end of a golden moment`, `fixation on perfection`, `an opportunity stirring up fear`, `marred beauty`, `danger at home`, `avoiding a journey`],
            desc: [],
            revdesc: [],
            major: [`The Devil`, `The Empress`, `The Hermit`, `The Magician`],
        },{
            id: 65,
            suit: `pentacles`,
            title: `Two of Pentacles`,
            subtitle: `Lord of Harmonious Change`,
            icons: [`♃`,`♑`],
            element: [`earth`],
            subelem: [],
            astro: [`Jupiter in the 1st decan of Capricorn`],
            subastro: [`0-10° of Capricorn (December 22- 30th) Jupiter in fall. Jupiter expands horizons and energizes the earthy energy of Capricorn.`],
            seph: [`Corresponding with Chokmah, the second sephira explores duality and choices.`],
            role: [],
            keys: [`juggling demands of life`, `balancing emotional and practical needs`, `giving and receiving`, `exchanging goods and news`, `ever evolving nature of life and understanding`, `dynamic balance`, `the world in motion`, `fleeting nature of life and moments`],
            revkeys: [`an overloaded schedule`, `struggle to balance competing needs and desires`, `commerce disrupted`, `systemic breakdown`, `avoiding news or change`, `hoarding or austerity`, `things at a standstill`, `delayed news or delivery`, `holding onto something beyond its moment`],
            desc: [],
            revdesc: [],
            major: [`The Wheel of Fortune`, `The Devil`, `The High Priestess`],
        },{
            id: 66,
            suit: `pentacles`,
            title: `Three of Pentacles`,
            subtitle: `Lord of Material Works`,
            icons: [`♂`,`♑`],
            element: [`earth`],
            subelem: [],
            astro: [`Mars in the 2nd decan of Capricorn`],
            subastro: [`10-20° of Capricorn (December 31- January 9th) Mars in exaltation. Mars goes to work laying plans and executing goals.`],
            seph: [`Corresponding with Binah, the third sephira gives form to concepts and begins to shape plans.`],
            role: [],
            keys: [`collaboration`, `building something through combined skills`, `collaboration`, `business savvy`, `finding one’s place in a hierarchy`, `building wealth or resources`, `practical inspiration`, `problem solving`, `showcasing skills`, `ideas taking form through action`],
            revkeys: [`competing motivations`, `tearing something down`, `poor business sense`, `competition impeding progress`, `unfruitful investment`, `over estimating skill level`, `disregard for hierarchy`, `struggling to effectively exhibit skills`],
            desc: [],
            revdesc: [],
            major: [`The Devil`, `The Tower`, `The Empress`],
        },{
            id: 67,
            suit: `pentacles`,
            title: `Four of Pentacles`,
            subtitle: `Lord of Earthly Power`,
            icons: [`☉`,`♑`],
            element: [`earth`],
            subelem: [],
            astro: [`Sun in the 3rd decan of Capricorn`],
            subastro: [`20-30° of Capricorn (January 10- 19th) Sun stimulates earthy Capricorn to productivity, and sheds light on structures of stability or power.`],
            seph: [`Corresponding with Chesed, the fourth sephira preserves and protects what has been established.`],
            role: [],
            keys: [`amassing resources and power`, `amassed resources and power offering a sense of security`, `resource guarding`, `conservation of wealth and resources`, `sharp mind for business and profit`, `selfish or controlling tendencies`, `using resources effectively`],
            revkeys: [`struggling to build wealth`, `confusing wealth and power for happiness`, `failing to respect resources`, `fair exchange`, `lost profits`, `gaining material success but not satisfaction`, `wealth and power do not guarantee security or safety`],
            desc: [],
            revdesc: [],
            major: [`The Devil`, `The Sun`, `The Emperor`],
        },{
            id: 68,
            suit: `pentacles`,
            title: `Five of Pentacles`,
            subtitle: `Lord of Material Troubles`,
            icons: [`☿`,`♉`],
            element: [`earth`],
            subelem: [],
            astro: [`Mercury in the 1st decan of Taurus`],
            subastro: [`0-10° of Taurus (April 21- 30th) Airy, intellectual Mercury is confronted with material demands and limitations. `],
            seph: [`Corresponding with Geburah, the fifth sephira puts all that is to the test, and dissolves what no longer serves.`],
            role: [],
            keys: [`material scarcity or strained resources`, `companionship in common cause`, `a spiritual or philosophic pivot`, `left out of societal or religious structures`, `a time of austerity or darkness`, `struggle for survival`, `rebelling against structure`, `mourning limitation`],
            revkeys: [`loss of faith`, `temporary reprieve`, `accepting limitation as reality`, `low expectations`, `refusing help`, `a short term solution`, `disempowerment`, `dependence on charity`, `an unsustainable situation`],
            desc: [],
            revdesc: [],
            major: [`The Hierophant, The Magician`],
        },{
            id: 69,
            suit: `pentacles`,
            title: `Six of Pentacles`,
            subtitle: `Lord of Material Success`,
            icons: [`☾`,`♉`],
            element: [`earth`],
            subelem: [],
            astro: [`Moon in the 2nd decan of Taurus`],
            subastro: [`10-20° of Taurus (May 1-10th) Moon in exaltation. The reflective and visionary Moon brings her freely flowing energy to the sign of growth and treasures. `],
            seph: [`Corresponding with Tiphareth, the sixth sephira is the sphere of regeneration, death and rebirth.`],
            role: [],
            keys: [`sharing treasures freely`, `sending resources where they are needed`, `balancing wealth`, `healing wealth gaps`, `coming together for mutual benefit`, `equanimity`, `humanizing others`, `kindness`, `generosity`, `practical benefits of charitable giving`],
            revkeys: [`conditional giving`, `penny pinching`, `indebtedness`, `constricted or threatened prosperity`, `hoarding resources`, `power imbalance`, `controlling people by controlling resources`, `greed`, `unequal access`, `dehumanizing others`, `socio-economic divide`],
            desc: [],
            revdesc: [],
            major: [`The Hierophant`, `The High Priestess`, `The Lovers`],
        },{
            id: 70,
            suit: `pentacles`,
            title: `Seven of Pentacles`,
            subtitle: `Lord of Success Unfulfilled`,
            icons: [`♄`,`♉`],
            element: [`earth`],
            subelem: [],
            astro: [`Saturn in the 3rd decan of Taurus`],
            subastro: [`20-30° of Taurus (May 11-20th) Saturn, planet of boundaries and structure, in the sign of fertility and growth.`],
            seph: [`Corresponding with Netzach, the seventh sephira is the sphere of artistic vision, and creative force.`],
            role: [],
            keys: [`long term investment`, `waiting`, `delayed gratification`, `cultivation`, `planning ahead`, `development in process`, `plans ripening`, `allowing time and space for development`],
            revkeys: [`counting chickens before they are hatched`, `impatience`, `shortsightedness`, `lack of planning`, `miscalculating resources or appropriate timeline for development`],
            desc: [],
            revdesc: [],
            major: [`The Hierophant`, `The World`, `The Chariot`],
        },{
            id: 71,
            suit: `pentacles`,
            title: `Eight of Pentacles`,
            subtitle: `Lord of Prudence`,
            icons: [`☉`,`♍`],
            element: [`earth`],
            subelem: [],
            astro: [`Sun in the 1st decan of Virgo`],
            subastro: [`0-10° of Virgo (August 23- September 1st) Sun brings illumination and attention to detail in the sign of work and service.`],
            seph: [`Corresponding with Hod, the eighth sephira tests, refines and fortifies the designs of Netzach.`],
            role: [],
            keys: [`dedication`, `diligence`, `honest work`, `honing skill`, `building a business or career`, `work with hands`, `achievement`, `surpassing original goals or visions`, `making money`, `attending to details`, `growth through work and service`],
            revkeys: [`careless work`, `inattention to detail`, `using skill for deceptive or destructive purpose`, `profiting off the work of others`, `failure to hone necessary skills`, `limited achievement`, `reaching a limit on what may be accomplished`, `over estimation of skill`],
            desc: [],
            revdesc: [],
            major: [`The Hermit`, `The Sun`, `Strength`],
        },{
            id: 72,
            suit: `pentacles`,
            title: `Nine of Pentacles`,
            subtitle: `Lord of Material Gain`,
            icons: [`♀`,`♍`],
            element: [`earth`],
            subelem: [],
            astro: [`Venus in the 2nd decan of Virgo`],
            subastro: [`10-20° of Virgo (September 2- 11th) Venus in fall. Bringing beauty and comfort to the sign of work and service.`],
            seph: [`Corresponding with Yesod, the ninth sephira lays foundations and inspires dreams.`],
            role: [],
            keys: [`abundance`, `firm boundaries`, `a protected or sacred space`, `security`, `comfort`, `controlled use of natural desires`, `prosperity`, `culmination of effort`, `privileged position`, `height of professional achievement`],
            revkeys: [`limited resources`, `a gilded cage`, `isolation`, `hedonistic use of natural desires`, `trapped by wealth`, `fear of losing wealth or status`, `professional or economic setback`],
            desc: [],
            revdesc: [],
            major: [`The Hermit`, `The Empress`],
        },{
            id: 73,
            suit: `pentacles`,
            title: `Ten of Pentacles`,
            subtitle: `Lord of Wealth`,
            icons: [`☿`,`♍`],
            element: [`earth`],
            subelem: [],
            astro: [`Mercury in the 3rd decan of Virgo`],
            subastro: [`20-30° of Virgo (September 12- 22nd) Mercury in exaltation. Mercury, planet of commerce and communication, unites and blesses communities in the sign of environment and work.`],
            seph: [`Corresponding with Malkuth, the tenth sephira is material manifestation of creative power, and the world we all inhabit.`],
            role: [],
            keys: [`generational wealth`, `legacy`, `room and resources for all to thrive`, `a life well lived`, `reflecting on experiences and achievements`, `intergenerational bonds`, `respect for differences`, `thriving community`, `rich history`, `productive communication`, `nurturing growth and innovation`],
            revkeys: [`generational or class conflict`, `competition for limited resources`, `lost inheritance`, `looking back with regret`, `a ruined legacy`, `insistence on uniformity`, `intolerance`, `conflict coming to a head`, `unpleasant truth or hidden information coming to light`, `conflict or adversity sparking change`],
            desc: [],
            revdesc: [],
            major: [`The Hermit`, `The Magician`, `The Wheel of Fortune`],
        },{
            id: 74,
            suit: `pentacles`,
            title: `Page of Pentacles`,
            subtitle: `Spirit of Earth`,
            icons: [`♑♉♍`],
            element: [`earth`],
            subelem: [],
            astro: [`all earth signs`],
            subastro: [`Capricorn`, `Taurus`, `Virgo`],
            seph: [`Corresponding with Malkuth, the tenth sephira is material manifestation of creative power, and the world we all inhabit.`],
            role: [`Messenger and Student`],
            keys: [`personification of earth uncomplicated by experience`, `cautious`, `deliberate`, `skilled`, `pragmatic`, `determined`, `nurturing`, `honest`, `solid`, `prefers gradual change`, `loves to study process and origins`, `loves comfort and beauty`, `keeper of the Ace of Pentacles`, `may bring welcome news regarding practical matters or harold new life`],
            revkeys: [`suspicious`, `fearful`, `entrenched`, `spurred to sudden action`, `deceives for personal gain`, `gravitates toward opulence`, `harsh pragmatism`, `may bring unwelcome news regarding practical matters or herold death and disease`],
            desc: [],
            revdesc: [],
            major: [`The Devil`, `The Empress`, `The Hermit`],
        },{
            id: 75,
            suit: `pentacles`,
            title: `Knight of Pentacles`,
            subtitle: `Lord of Virgo`,
            icons: [`♍`,`♌`],
            element: [`earth`],
            subelem: [`Knights combine the element of Fire with their native element.`],
            astro: [`Virgo, mutable earth`],
            subastro: [`20° of Leo- 20° of Virgo (August 12- September 11th) Herald of Fall`],
            seph: [`Corresponding with Tiphareth, the sixth sephira is the sphere of regeneration, death and rebirth.`],
            role: [`Hero and Quester`],
            keys: [`steady`, `deliberate`, `thoughtful`, `practical`, `meticulous planner`, `unstoppable`, `healer`, `reliable`, `conscientious`, `smart investor`, `favors long term success over short term profit`, `grounded`, `guided`, `productive`, `hard working`, `does social service for love of community`, `actions motivated by personal ethics`, `labor of love`, `his quest is to improve life for all, and bring dreams into being`],
            revkeys: [`inconstant`, `unreliable`, `has blinders on`, `fails to recognize need for adaptation`, `materialistic`, `jealous`, `recognition and praise are the goal of good doing`, `favors short term profit over long term success`, `he strives to amass personal wealth and power`],
            desc: [],
            revdesc: [],
            major: [`The Hermit`],
        },{
            id: 76,
            suit: `pentacles`,
            title: `Queen of Pentacles`,
            subtitle: `Lady of Capricorn`,
            icons: [`♑`,`♐`],
            element: [`earth`],
            subelem: [`Queens combine the element of Water with their native element.`],
            astro: [`Capricorn, cardinal earth`],
            subastro: [`20° of Sagittarius- 20° Capricorn (December 13- January 9th) Initiates Winter.`],
            seph: [`Binah`],
            role: [`Nurturer and Guardian`],
            keys: [`fertility`, `savvy leader`, `authoritative`, `wise`, `business minded`, `practical`, `resourceful`, `values tradition`, `cautious`, `delights in sensual pleasures`, `aware of status and hierarchy`, `an old soul`, `understands cycles and investments`, `she protects and nurtures the resources of her community, and plans ahead for times of hardship or disaster`],
            revkeys: [`infertility`, `a poor or miscalculating leader`, `unwise`, `struggles to use knowledge effectively`, `flouts tradition`, `strongly motivated by greed or lust`, `fails to understand a hierarchy or structure that surrounds her`, `locked into the status quo`, `fatalistic thinking`, `fails to respect resources or plan ahead`],
            desc: [],
            revdesc: [],
            major: [`The Devil`],
        },{
            id: 77,
            suit: `pentacles`,
            title: `King of Pentacles`,
            subtitle: `Lord of Taurus`,
            icons: [`♉`,`♈`],
            element: [`earth`],
            subelem: [`Kings combine the element of Air with their native element.`],
            astro: [`Taurus, fixed earth`],
            subastro: [`20° of Aries- 20° of Taurus (April 11- May 10th) Spring in its power`],
            seph: [`Chokmah`],
            role: [`Keeper and Administrator`],
            keys: [`practical`, `patient`, `loyal`, `slow changing`, `stubborn`, `experienced`, `honest`, `diligent`, `protective`, `realistic`, `loves comfort and beautiful things`, `generous`, `slow to anger, but fierce when roused`, `problem solver`, `his home reflects his values`, `he values methodical development and insists that plans be evidence based`, `he likes to plan well in advance so that necessary change need not be too disruptive or jarring`],
            revkeys: [`stubborn`, `bullish`, `throws his weight around`, `opulent`, `vain`, `motivated by lust and greed`, `looks for short term solutions`, `poor planner`, `miserly`, `exploitative`, `unreliable`, `unorganized`, `constantly averting the next crisis rather than engage long term solutions`, `values convenience over correct action`, `fails to consider the impact of actions`, `believes that the world and all its wealth exists for his own delight and service`],
            desc: [],
            revdesc: [],
            major: [`The Hierophant`],
        }]
    ));
}, []);

    const clickUpdateDeck = async () => {
        const addTest = collection(fs, 'deck');
        return addDoc(addTest, {
            id: `deck`,
            timestamp: Date(),
            deck: deck
        });
    };
    return(
        <button onClick={clickUpdateDeck}>update deck info</button>
    );
};
export default Update;